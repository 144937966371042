import App from '../main';
// stype ctype utag  参数顺序
let websock = null;
let GlobalCallback = null;
let GlobalStype = null;
let GlobalArray = [];
let Loading = null;

// // 初始化weosocket
function InitSock(ws) {
    return new Promise((resolve, reject) => {
        console.log(`准备连接的地址是${ws}`);
        websock = new WebSocket(ws);
        websock.binaryType = 'arraybuffer';
        websock.onmessage = function (e) {
            websocketonmessage(e);
        };
        websock.onclose = function (e) {
            websocketclose(e);
        };
        websock.onopen = function () {
            websocketOpen();
            resolve('链接成功');
        };
        // 连接发生错误的回调方法
        websock.onerror = function () {
            console.log('WebSocket连接发生错误');
            reject();
        };
    });
}


// 实际调用的方法
function SendSock(AgentData, Callback) {
    if (Callback) {
        GlobalStype = AgentData[0];
        GlobalCallback = Callback;
        if (Callback !== 'CallOutRoomFn') Loading = App.Toast('3');
        if (!App.$store.state.Heartbeat) { //阻止强行刷新导致页面无反应  应该返回登录页面
            if (!sessionStorage.LaunchToken) {
                if (sessionStorage.SYSLang === 'CH') {
                    App.NextStepDo('参数错误，是否返回登录', 666);
                } else {
                    App.NextStepDo('Lỗi tham số, trở lại đăng nhập', 666);
                }
            }
            Loading.clear();
        }
    }
    if (websock.readyState === websock.OPEN) {
        // 若是ws开启状态
        websocketsend(AgentData);
    } else if (websock.readyState === websock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function () {
            SendSock(AgentData, Callback);
        }, 1000);
    } else {
        // 若未开启 ，则等待1s后重新调用
        // setTimeout(function () {
        //     SendSock(AgentData, Callback);
        // }, 1000);
    }
}

function CloseSock() {
    return new Promise((resolve, reject) => {
        websock.close();
        resolve();
    });
}

function ab2str(buf) {
    return decodeURIComponent(escape((String.fromCharCode.apply(null, new Uint8Array(buf)))));  //解决中文乱码问题
}


// 字符串转为ArrayBuffer对象，参数为字符串
function str2ab(str) {
    let buf = new ArrayBuffer(str.length); // 每个字符占用2个字节
    let bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}


function doSend(bytes) {
    let buffer = new ArrayBuffer(bytes.byteLength + 12);
    let view = new DataView(buffer);
    view.setUint32(0, GlobalArray[0], true);
    view.setUint32(4, GlobalArray[1], true);
    view.setUint32(8, GlobalArray[2], true);
    let uint8a = new Uint8Array(bytes);
    for (let i = 0; i < uint8a.byteLength; i++) {
        // view.setUint8(i + 12, uint8a[i], true);
        view.setUint8(i + 12, uint8a[i]);
    }
    return view;
}


// 数据接收
function websocketonmessage(e) {
    var buffer = e.data;
    // console.log(new DataView(new Uint8Array(e.data).buffer).getUint32(8, true));
    var stype = new DataView(e.data).getUint32(0, true);
    var ctype = new DataView(e.data).getUint32(4, true);
    var utag = new DataView(e.data).getUint32(8, true);
    var buf1 = buffer.slice(12, buffer.length);
    var cmd = JSON.parse(ab2str(buf1));
    Loading.clear();
    // if (stype === 400 && ctype === 441) { //账号在其他地方登录
    //     sessionStorage.toroot = true;
    //     sessionStorage.isDialog = true; //此时表示不需要在调用socket命令
    //     vm.dialog('账号在其他地方登录，是否返回登陆', '9999999', () => {
    //         sessionStorage.removeItem('toroot');
    //         vm.$router.replace('/');
    //     });
    // }
    // // console.log(stype, ctype, utag, cmd);
    App.CtypeActive(stype, ctype, utag, cmd); //进行主动推送判断
    if (cmd !== null && App.NextStep(cmd.status) === true && stype === GlobalStype && GlobalCallback) {
        App.SetStore({  //添加标识到vuex
            Prop: 'SocketFn',
            Val: {Methods: GlobalCallback, Data: cmd}
        });
        GlobalCallback = GlobalStype = null;
    }
    if (cmd !== null && App.NextStep(cmd.status) !== true) App.NextStepDo(App.NextStep(cmd.status), cmd.status);
}

// 数据发送
function websocketsend(AgentData) {
    var str = JSON.stringify(AgentData[3]);
    var buf = str2ab(str);
    GlobalArray = AgentData.filter((item, index) => index < 3);
    websock.send(doSend(buf));
}

// 关闭
function websocketclose() {
    if (sessionStorage.Heartbeat === 'false') return;
    sessionStorage.Heartbeat = false;
    if (!sessionStorage.ToRoot){
        if (sessionStorage.SYSLang === 'CH') {
            App.NextStepDo('服务器连接断开', '98');
        } else {
            App.NextStepDo('Dịch vụ tắt', '98');
        }
    }
}

// 创建 websocket 连接
function websocketOpen(e) {
    App.SetStore({
        Prop: 'Heartbeat',
        Val: true
    });
    sessionStorage.Heartbeat = true;
}

// 将方法暴露出去
export {
    InitSock,
    SendSock,
    CloseSock
}
