<template>
    <div v-show="ShowGameAppDown" class="GameAppDown fixed position-center flex column">
        <div class="margin-auto mat15 mab15 flex items-center content-center">
            <img class="allh" :src="'./img/Common/shoujixiazai'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">
            <img @click="SetStore({'Prop':'ShowGameAppDown',Val:false})" class="absolute pointer"
                 src="../../../public/img/Common/gn_gb.png" alt="">
        </div>
        <div class="flex1 flex">
            <div class="font16 flex1 flex column items-center content-center">
                <img :src="'./img/Common/a_xiazai'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'" alt="">
                <img :src="AZImg">
<!--                扫一扫，下载手机客户端-->
                {{Lang['SYS']}}
            </div>
            <div class="font16 flex1 flex column items-center content-center">
                <img :src="'./img/Common/p_xiazai'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'" alt="">
                <img :src="IOSImg">
                <!--                扫一扫，下载手机客户端-->
                {{Lang['SYS']}}
            </div>
        </div>
    </div>
</template>

<script>
    import {qrcanvas} from 'qrcanvas';
    import {mapState} from 'vuex';
    import {ApiGetConfig} from "../../Api";

    export default {
        name: 'GameAppDown',
        props: ['ShowChip', 'ShowChipPond', 'MaxNum', 'Count'],
        computed: {...mapState(['ShowGameAppDown', 'LangeuageAgent'])},
        data() {
            return {
                AZImg: '',
                IOSImg: ''
            }
        },
        watch: {
            'ShowGameAppDown': {
                handler(n, o) {
                    if (!n) return;
                    ApiGetConfig({}).then((resp) => {
                        this.AZImg = this.TransformCanvas(resp.data.appDownloadUrl + '?' + Math.ceil(Math.random() * 1000000));
                        this.IOSImg = this.TransformCanvas(resp.data.appDownloadUrl+ '?' + Math.ceil(Math.random() * 1000000));
                    });
                }, deep: true, immediate: true
            }
        },
        methods: {
            TransformCanvas(Url) {  //转换画布
                let canvas = qrcanvas({
                    data: Url,
                });
                return canvas.toDataURL("image/png");
            }
        }
    }
</script>

<style lang="less">
    .GameAppDown {
        width: 8.6rem;
        height: 5.5rem;
        background-color: rgba(0, 0, 0, .6);
        border: solid 1px #8e7056;
        z-index: 100;

        > div:first-child {
            width: 4.2rem;
            height: .35rem;
            background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;

            img:last-child {
                right: .2rem;
                width: .3rem;
                height: .3rem;
            }
        }

        > div:last-child {
            border-top: solid 1px #8e7056;
            background-color: #000;
            color: #b5b6b1;

            img:nth-child(2) {
                width: 2.35rem;
                height: 2.35rem;
                padding: .1rem;
                background-color: #ffffff;
                margin: .2rem 0;
            }
        }
    }
</style>
