import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        UserInfo: {}, //用户信息
        HallInfo: {}, //大厅信息  用于键值对直接存取
        InsideGame: {}, //游戏内部id 包括多台 组件加载pushID 组件消失的时候清除对应ID 用于stype === 1推送的消息验证
        MultipleId: [], //多台ID
        Heartbeat: false, //是否开启socket心跳
        SocketFn: {}, //唯一标识 当前执行的方法
        ShowLogin: true, //显示登录页面
        Tips: '尊敬的贵宾：您好，如因网络问题或者其他因素造成牌路显示有误，《如：黑屏.串屏.露珠对不上等等》',
        DefaultConfig: {},  //默认配置
        ShowRegister: false, //注册
        ShowVoice: false, //修改声音
        VoiceNum: 0.5,
        ShowPassword: false,  //修改密码
        ShowGameRecords: false,  //游戏记录
        ShowGameRecharge: false,  //充值
        ShowGameAppDown: false,  //App下载
        ShowGameOnline:false,   //在线客服
        quickOnlineShowBtn:true, //快捷登录是否展示在线客服
        whdef: 1, //window的宽与1920的比例
        DealVideo: { //是否互换video
            index: '', //当前视频的下标
            count: 0, //已经进行互换时为2
            initiator: ''  //发起者
        },
        IsAskRoadBool: true, //问路
        ShowGameRules: false,
        ChangeChip: false, //是否改变筹码
        ReInit: false, //是否需要重新配置请求地址
        OnlineNum: 0,    //在线人数
        // LangeuageAgent:'VNĐ'  //默认语言包
        LangeuageAgent:'CH'  //默认语言包
    },
    mutations: {
        SetStore(State, Arg) {  //深度设置State的值
            let ForArray = (InArg) => {
                let {Prop, Val, AddName, NeedStorage} = InArg;  //{属性/或者是父属性  属性值  添加的名字/重复添加就是替换  是否需要本地缓存/sessionStorage}
                let Fn = (Agent) => {
                    let NameArray = Object.keys(Agent);
                    if (NameArray.indexOf(Prop) >= 0) {
                        if (AddName) {
                            Agent[Prop][AddName] = Val;
                        } else {
                            Agent[Prop] = Val;
                        }
                        if (NeedStorage) sessionStorage[Prop] = JSON.stringify(Agent[Prop]);
                    } else {
                        let InFn = (InNameArray) => {
                            InNameArray.forEach((item) => {
                                Fn(Agent[item]);
                            });
                        };
                        InFn(NameArray);
                    }
                };
                Fn(State);
            };
            if (Arg instanceof Array) {
                Arg.forEach((item) => {
                    ForArray(item);
                });
            } else {
                ForArray(Arg);
            }
        },
    },
    actions: {},
    modules: {}
})
