<template>
    <div v-show="ShowGameOnline" class="GameAppDown fixed position-center flex column">
        <div class="margin-auto mat15 mab15 flex items-center content-center">
            <!--            <img class="allh" :src="'./img/Common/shoujixiazai'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">-->
            <p class="font42" style="color: #ffdf79">在线客服</p>
            <img @click="SetStore({'Prop':'ShowGameOnline',Val:false})" class="absolute pointer"
                 src="../../../public/img/Common/gn_gb.png" alt="">
        </div>
        <div class="flex1 content-center text-center">
            <div v-for="(item,index) in onlineServiceList" @click="toOnline(item)" :key="index"
                 style="width:50%;margin:.2rem 0;float: left;">
                <div class="toOnlineBtn margin-auto font20 mat10 pointer border-box relative"
                     style="padding: 0;text-align: center">
                    {{Lang['ZXKF']}}{{index + 1}}<!--在线客服-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {onlineService} from "../../Api";

    export default {
        name: 'GameOnline',
        computed: {...mapState(['ShowGameOnline', 'LangeuageAgent', 'UserInfo'])},
        data() {
            return {
                onlineServiceList: []
            }
        },
        watch: {
            'ShowGameOnline': {
                handler(n, o) {
                    if (!n) return;
                    this.onlineServiceList = []
                    this.getOnline()
                }, deep: true, immediate: true
            }
        },
        methods: {
            getOnline() {
                onlineService({}).then(res => {
                    let _url = !res.data.url ? [] : res.data.url.split('|')
                    if (!_url.length) {
                        this.Toast('2', '客服不在线')
                        return
                    }
                    this.onlineServiceList = _url
                })
            },
            toOnline(item) {
                if (item.indexOf('?') >= 0) {
                    window.open(item + `&visitor_name=${this.UserInfo.username}`)
                } else {
                    window.open(item + `?visitor_name=${this.UserInfo.username}`)
                }
            }
        }
    }
</script>

<style lang="less">
    .GameAppDown {
        width: 6.5rem;
        height: 5.5rem;
        background-color: rgba(0, 0, 0, .6);
        border: solid 1px #8e7056;
        z-index: 100;

        > div:first-child {
            width: 4.2rem;
            height: .35rem;
            background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;

            img:last-child {
                right: .2rem;
                width: .3rem;
                height: .3rem;
            }
        }

        > div:last-child {
            .toOnlineBtn {
                width: 2rem;
                line-height: .44rem;
                background: #050505 url("../../../public/img/GameCenter/list_bg.png") no-repeat center center/100% 100%;
                text-align: right;
                padding-right: .2rem;
                color: #cecece;

                img {
                    width: .3rem;
                    left: .1rem;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                img:nth-child(2) {
                    display: none;
                }

                &:hover {
                    background: #050505 url("../../../public/img/GameCenter/list_bg_hover.png") no-repeat center center/100% 100%;
                    color: #E3C0A3;

                    img:first-child {
                        display: none;
                    }

                    img:nth-child(2) {
                        display: block;
                    }
                }
            }
        }
    }
</style>
