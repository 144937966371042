import App from '../main';

const myMixin = {
    data() {
        return {
            // isPlay: false, // 是否播放
            Lang: {LANG: 'CH'},
            Sfullscreen: false, //是否全屏
            AskTimer: null,
            CanvasXY: [],  //记录canvas操作
            RandomCanvas: '',  //canvas跟新标识
            WhellContent: 0, //滑轮滚动次数
            QuestionAudio: '', // 音频
            CurrentAudio: [],  //当前播放的语音集合
            stype: {
                Center: 400,
                Hall: 401,
                Heartbeat: 402,
                TalkRoom: 403,
            },
            cmd: {
                UserDisconnect: 10000,//用户掉线
                UserLogin: 1,//用户登陆
                EnterHall: 2, //进入大厅
                ExitHall: 3, //离开大厅
                EnterRoom: 4, //进入游戏
                ExitRoom: 5, //离开游戏
                PlayerBet: 6,  //玩家下注
                CancelBet: 7,  //撤销下注
                SignLogin: 9,  //签名登录
                ReBalance: 13, //刷新余额
                //打开转台
                //进入多台
                //关闭转台
                //游戏转台
                //多台转台
                //本局结束通知
                OnGameOver: 394,
                OnCheckOut: 395, //结算结果
                OnPublishResult: 396, //发布游戏结果通知
                OnStartDeal: 397,//开始发牌通知
                OnStartBet: 398, //开始下注通知
                OnRemoveRoom: 399,//从游戏服务关闭
                OnLotteryPool: 400, //彩池更新通知
                DealerLogin: 401,//荷官登陆
                DealerStartBet: 402,//开始下注
                DealerStartDeal: 403,//开始发牌
                DealerPublishResult: 404, //发布结果
                ReLogin: 441, //账号在其他地方登陆
                AlreadyLogin: 442, //已经登陆成功
                HallConnect: 443,//大厅服务器连接游戏服务器
                HeartBeat: 444,//心跳
            },
            LangTransTips: {
                'CH': {
                    'FWQWLJ': '服务器未连接，是否返回登录',
                    'ZYTX': '重要提醒',
                    'ZWQX': '暂无权限',
                    'ZHZQTDFDL': '账号在其他地方登录，请返回登陆'
                },
                'VNĐ': {
                    'FWQWLJ': 'Máy chủ không kết nối, quay lại đăng nhập',
                    'ZYTX': 'Nhắc lại quan trọng',
                    'ZWQX': 'Không đồng ý',
                    'ZHZQTDFDL': 'Việc đăng nhập tài khoản ở các nơi khác, xin trở lại để đăng nhập'
                },
            }
        }
    },
    watch: {
        'SocketFn': {
            handler(n) {
                let {Methods: _Methods, Data} = n;
                if (this.CallBack.includes(_Methods)) this[_Methods](Data);
            }, deep: true
        },
        'LangeuageAgent': {
            handler(n) {
                if (n) this.Lang = this.Language[this.LangeuageAgent];
            }, deep: true, immediate: true
        }
    },
    methods: {
        SetStore(Val) {
            App.$store.commit('SetStore', Val);
        },
        RouterTo(Url, Val) { //路由跳转
            if (Url === '暂未开放') {
                App.Toast('2', Url);
                return;
            }
            if (sessionStorage.Heartbeat === 'false') { //服务器未连接
                App.Dialog(App.LangTransTips[sessionStorage.SYSLang]['FWQWLJ'], {   //服务器未连接，是否返回登录
                    Time: 3600 * 24,
                    Qx: true,
                    CallBack: () => {
                        App.$router.replace('/');
                    }
                });
                return;
            }
            let Time = 0;
            let _router = this.$route;
            if (Url === _router.name && (JSON.stringify(Val) === JSON.stringify(_router.query) || !Val)) return;
            if (Url === 'GameHall') sessionStorage.removeItem('NeedLeave'); //删除多台离开房间印记
            App.$router.push({
                path: Url,
                name: Url,
                query: Val
            });
        },
        Toast(Type, Msg, Options = {}) {    //消息提示 1.成功 2.警告 3.加载 4.消息
            Options = {...{Time: 3, OverLay: false}, ...Options};
            let MsgType = {
                '1': 'success',
                '2': 'fail',
                '3': 'loading',
                '4': 'html',
                '5': 'clear'
            };
            let {Time, CallBack, OverLay} = Options;
            const Loading = App.$toast({
                type: MsgType[Type],
                message: Msg,
                duration: Time * 1000,
                overlay: Type === '3' || OverLay,
                onClose() {
                    CallBack && CallBack();
                }
            });
            return Loading;
        },
        Dialog(Msg, Options = {}) { //对话框
            Options = {
                ...{
                    Time: 10,
                    Title: App.LangTransTips[sessionStorage.SYSLang]['FWQWLJ'],
                    Qr: true,
                    Qx: false
                }, ...Options
            };//'重要提醒'
            let {Time, Title, CallBack, Qr, Qx} = Options;
            let AlreadyDone = false;
            // let showCancelButton = ['服务器未连接，是否返回登录', '参数错误，是否返回登录'];//, '服务器连接断开'
            this.$dialog({
                title: Title,
                message: Msg,
                lockScroll: false,
                showConfirmButton: Qr,    //message === '您已离开游戏，请关闭浏览器' ? false : true
                showCancelButton: Qx, //showCancelButton.includes(message)
                confirmButtonText: sessionStorage.SYSLang === 'CH' ? '确认' : 'Xác nhận',   //确认按钮文案
                cancelButtonText: sessionStorage.SYSLang === 'CH' ? '取消' : 'Hủy',    //取消按钮文案
            }).then(() => {
                AlreadyDone = true;
                CallBack && CallBack();
                this.$dialog.close();
            });
            setTimeout(() => {
                if (!AlreadyDone) {
                    CallBack && CallBack();
                    this.$dialog.close();
                }
            }, Time * 1000);
        },
        RequireImg(Url) {   //找到图片路径
            let Arr = Url.split('.');
            return `./img/${Arr[0]}${this.LangeuageAgent === 'CH' ? '' : this.LangeuageAgent}.${Arr[1]}`;
        },
        Clone(Params) {
            return JSON.parse(JSON.stringify(Params));
        },
        ToString(Params) {
            return JSON.stringify(Params);
        },
        ToParse(Params) {
            return JSON.parse(Params);
        },
        CountCanvas(H, N) { //计算canvas的高度 H:原始高度  N:份数
            let CountH = H - 1; //计算整除凑整  1~2 （6） 1~2 向中间靠拢
            let Remainder = this.CountH % N;
            if (Remainder <= parseInt(N / 2)) this.CountH -= Remainder;   //多了可忽略像素
            if (Remainder >= parseInt(N / 2) + 1) this.CountH += (6 - Remainder);   //少了可忽略像素
            return CountH;
        },
        HandlePlayAudio(url) {
            let DontTrans = ['撤销下注特效', '倒计时铛铛声'];
            this.CurrentAudio = url.split(',');
            this.QuestionAudio = `./sound/${this.CurrentAudio[0]}${(this.LangeuageAgent !== 'CH' && !DontTrans.includes(this.CurrentAudio[0])) ? this.LangeuageAgent : ''}.mp3`;
            this.PlayAudio();
        },
        AudioEnd() {    //音频停止后的回调  监听是否连续播放
            let audio = this.$refs.audio;
            let DontTrans = ['撤销下注特效', '倒计时铛铛声'];
            audio.pause();
            this.CurrentAudio.splice(0, 1);  //消除掉上一次语音播放
            if (this.CurrentAudio.length > 0) {
                setTimeout(() => {
                    this.QuestionAudio = `./sound/${this.CurrentAudio[0]}${(this.LangeuageAgent !== 'CH' && !DontTrans.includes(this.CurrentAudio[0])) ? this.LangeuageAgent : ''}.mp3`;
                    this.PlayAudio();
                }, 0)
            }
        },
        PlayAudio() {
            setTimeout(() => {
                this.$refs.audio.volume = this.$store.state.VoiceNum; //设置音量
                this.$refs.audio.play();
            }, 0);
        },
        CountUrl(temporaryMoney) {  //计算筹码图片
            let AllMoney = [1000000, 500000, 100000, 50000, 10000, 5000, 1000, 500, 100, 50, 20, 10, 5, 1];
            let url = [];
            AllMoney.forEach((item, index) => {
                if (temporaryMoney / item > 0) {
                    let count = parseInt(temporaryMoney / item);
                    for (let i = 0; i < count; i++) {
                        url.push(item);
                    }
                    temporaryMoney -= item * count;
                }
            });
            return url;
        },
        IsPermission(showToast = true) { //是否有权限
            let bool = sessionStorage.Authorization || false;
            if (!bool && showToast) this.Toast('2', App.LangTransTips[sessionStorage.SYSLang]['ZWQX']);//'暂无权限'
            return bool;
        },
        WhellControl(Agent, Event) { //自定义模拟滚动条
            let DomH = this.$refs[Agent].clientHeight;
            let WilltransForm = this.$refs[Agent].children[0];
            let WilltransFormH = this.$refs[Agent].children[0].clientHeight;
            let fn = (direction) => {
                let MainCount = Math.ceil((WilltransFormH - DomH) / (40 * this.whdef));
                if (direction === "down") {
                    if (Math.abs(this.WhellContent) >= MainCount) {
                        WilltransForm.style.transform = `translate(0,-${WilltransFormH - DomH}px)`;
                        return;
                    }
                    //     if (Math.abs(this.WhellContent * 40) + DomH >= WilltransFormH) { //验证最后一次滚动
                    //         WilltransForm.style.transform = `translate(0,-${WilltransFormH - DomH}px)`;
                    //         return;
                    //     }
                    this.WhellContent--;
                }
                if (direction === "up" && this.WhellContent !== 0) this.WhellContent++;
                WilltransForm.style.transform = `translate(0,${this.WhellContent * 40 * this.whdef}px)`;
                return;
            };
            if (WilltransFormH <= DomH) return;
            if (Event === true) fn();
            let {deltaY} = Event;
            if (deltaY > 0) fn("down");
            if (deltaY < 0) fn("up");
        },
        HandleFullScreen() {
            let element = document.documentElement;
            // 判断是否已经是全屏
            // 如果是全屏，退出
            if (this.Sfullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {    // 否则，进入全屏
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            // 改变当前全屏状态
            this.Sfullscreen = !this.Sfullscreen;
        },
        ShowAskRoad(state, name, num, unit) {
            clearInterval(this.AskTimer);
            let canvas = this.$refs[name + 1];
            let context = canvas.getContext('2d');
            let item = this.RoadList['AskRoad'][name][state === 'z' ? 0 : 1];
            let _this = this;
            let x = 0;
            let y = 0;
            let countNum = 0;
            let farmat = this.CanvasXY;
            this.RandomCanvas = Math.random();
            if (farmat.length !== 0) context.clearRect(farmat[0], farmat[1], farmat[2], farmat[2]);
            if (!state) {
                App.SetStore({Prop: 'IsAskRoadBool', Val: false});
                return;
            }
            let fn = () => {
                if (!item) return;
                let img = new Image();
                img.src = `./img/${localStorage.type}/${item.url}${_this.Lang.LANG === 'CH' ? '' : _this.Lang.LANG}.png`;
                img.onload = function () {
                    x = num * item.x + (unit * (2 * item.x + 1));
                    y = num * item.y + (unit * (2 * item.y + 1));
                    _this.CanvasXY = [x, y, num];
                    context.drawImage(img, x, y, num, num);
                }
            };
            fn();
            this.AskTimer = setInterval(() => {
                if (countNum === 8) {
                    this.RandomCanvas = '';
                    App.SetStore({Prop: 'IsAskRoadBool', Val: false});
                    clearInterval(this.AskTimer);
                    return;
                }
                if (countNum % 2 === 0) { //显示
                    canvas.style.display = 'block';
                } else {
                    canvas.style.display = 'none';
                }
                countNum++;
            }, 500);
        },
        GetGameStatus(TableInfo) {
            if (TableInfo.ci === 0) return this.Lang['XPZ'];//'洗牌中'
            let StatusObj = {
                '0': '',
                '1': this.Lang['KPZ'],//开牌中
                '2': this.Lang['KPZ'],//开牌中
                '3': this.Lang['JSZ']//结算中
            };
            return StatusObj[TableInfo.gameStatus];
        },
        CtypeActive(Stype, Ctype, Utag, Cmd) {
            // console.log(Stype, Ctype, Utag, Cmd);
            if (Stype === 400 && Ctype === 441) { //账号在其他地方登录
                sessionStorage.ToRoot = true;   //防止主动弹框时其他弹框
                // sessionStorage.isDialog = true; //此时表示不需要在调用socket命令
                App.Dialog(App.LangTransTips[sessionStorage.SYSLang]['ZHZQTDFDL'], {//'账号在其他地方登录，请返回登陆'
                    Time: 9999999, Qr: true, CallBack: () => {
                        sessionStorage.removeItem('ToRoot');
                        App.$router.replace('/');
                    }
                });
            }
            if (Stype !== 402) {
                // let cmd = this.clone(_cmd);
                if (Stype === 401) {  //大厅通知 侧边换桌通知
                    let HallInfo = App.Clone(App.$store.state.HallInfo);
                    if (HallInfo.hasOwnProperty(Cmd.gameId)) {  //当前大厅列表有此台桌
                        Cmd.change = false; //默认不需要更新露珠
                        if (Cmd.hasOwnProperty('roadList')) {
                            Cmd.transForm = this.Calculation.road(Cmd.roadList, true);
                            Cmd.change = true;  //提醒需要重绘露珠
                        }
                        if (Ctype === 392) {//换靴
                            HallInfo[Cmd.gameId].roadList = [];
                            HallInfo[Cmd.gameId].transForm = [];
                            Cmd.change = true;  //提醒需要重绘露珠
                        }
                        HallInfo[Cmd.gameId] = {...HallInfo[Cmd.gameId], ...Cmd};  //更新到最新状态
                    }
                    App.SetStore({ //最终把内容替换掉
                        Prop: 'HallInfo',
                        Val: HallInfo
                    });
                }
                if (Stype !== 400 && Ctype !== 401) {  //具体台桌通知
                    let Val = App.Clone(App.$store.state.InsideGame);
                    Val[Cmd.gameId] = Cmd;
                    App.SetStore({
                        Prop: 'InsideGame',
                        Val
                    });
                }
                if (Stype === 403 && Ctype === 391) {  //更新余额
                    App.SetStore({
                        Prop: 'UserInfo',
                        AddName: 'balance',
                        Val: Cmd.balance
                    });
                }
                if (Stype === 403 && Ctype === 387) {  //更新滚动通知
                    //     console.log('新通知', stype, ctype, utag, _cmd);
                    //     // let userInfo = this.clone(this.$store.state.userInfo);
                    //     // userInfo = {...userInfo, ...{balance: cmd.balance}};
                    //     // App.setStore({
                    //     //   Prop: 'userInfo',
                    //     //   Val: userInfo
                    //     // });
                }
                if (Stype === 403 && Ctype === 383) {  //更新在线人数
                    App.SetStore({
                        Prop: 'OnlineNum',
                        Val: Cmd.onlineNum
                    });
                }
            }
        },
        NextStep(state) {
            let stateResult = {
                '1': true,
                '93': '操作失败',
                '94': '服务返回参数错误',
                '95': '改写UID失败',
                '96': '改写uTag失败',
                '97': '不是登陆操作，但是uid为0',
                '98': '服务未连接',
                '99': '系统错误',
                '100': '表示用户传递的参数错误',
                '101': '账号被禁用',
                '102': '账号密码错误',
                '103': '玩家已经在房间',
                '104': '游戏房间不存在',
                '105': '进入房间出错',
                '106': '玩家不在大厅服务',
                '107': '玩家不在服务',
                '108': '不在下注时间不能下注',
                '109': '非法注单',
                '110': '低于最小限红',
                '111': '高于最大限红',
                '112': '下注金额必须大于0',
                '113': '玩家余额不足',
                '114': '玩家不在房间',
                '115': '连接connID为0',
                '116': '荷官登陆密码不正确',
                '117': '荷官端链接不一致',
                '118': '游戏状态不正确不能操作',
                '119': '开奖结果格式不正确',
                '120': '结算失败',
                '121': '发布开奖结果失败',
                '122': '获取玩家信息失败',
                '124': '账号已在线，请重新登录'
            };
            return stateResult[state];
        },
        NextStepDo(Str, State) {  //666是自定义回到登录页面
            if ((State > 250 && State !== 666) || !State) return; //防止游戏正常推送也弹框
            let Time = 3600 * 24; //默认无限时间
            let Fn = null;  //默认的回调函数
            let OneSecode = [101, 102, 108, 109, 110, 111, 112, 113, 123, 128];
            let Url = {'114': {u: 'GameCenter'}, '666': {u: '/'}, '98': {u: '/'}};
            if (OneSecode.includes(State)) Time = 1;
            if (State === 114 || State === 666 || State === '98') {
                Fn = () => {
                    App.$router.replace(Url[State]['u']);
                };
            }
            if (State === 124) {  //登录主动被踢
                sessionStorage.ToRoot = true;
                Fn = () => {
                    App.SetStore({
                        Prop: 'ReInit',
                        Val: true
                    });
                    sessionStorage.removeItem('ToRoot');
                };
            }
            // sessionStorage.isDialog = true; //此时表示不需要在调用socket命令
            App.Dialog(Str, {Time, CallBack: Fn, Qx: State === 666});
            // , Qr: Fn ? true : false
        },
    }
};
export default myMixin;
