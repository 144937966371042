<template>
    <div v-show="ShowGameRules" class="GameRules fixed allw allh">
        <div class="setbox absolute position-center flex column relative">
            <div class="margin-auto mat15 mab15 flex items-center content-center">
                <img class="allh"
                     :src="'./img/Common/title_yxgz'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">
                <img @click="SetStore({Prop:'ShowGameRules',Val:false})" class="absolute pointer"
                     src="../../../public/img/Common/gn_gb.png" alt="">
            </div>
            <div class="flex1 flex column">
                <div :class="[LangeuageAgent === 'CH' ? 'font30' : 'font25']" class="TitleBox flex text-center">
                    <p v-for="(item,index) in TitleArr" :key="index" @click="CurrentIndex = index"
                       :class="{'OnHover':index === CurrentIndex}"
                       class="mar15 pointer">
                        {{Lang[item]}}
                    </p>
                </div>
                <div @mousewheel="WhellControl('WhellDivRules',$event)" ref="WhellDivRules"
                     class="ContentBox relative flex1 mab20 overflow-hidden">
                    <div v-if="LangeuageAgent === 'CH'" class="absolute border-box allw">
                        <template v-if="CurrentIndex === 0">
                            <p class="TieleP">一、简介</p>
                            <p>百家乐是扑克游戏，也是赌场中常见的赌博游戏之一。是当今世界各地赌场最受欢迎的赌戏。</p>
                            <p class="TieleP">二、投注&玩法</p>
                            <p>玩家如认为庄闲开出的最终点数一样，不能分出胜负，可押注和局;玩家如认为庄、闲任一方（或两方同时)
                                前两张牌会出现一对（两张牌相同点数），可押注对子﹔玩家如认为庄、闲任一方最终点数会较大，可押注认为点数大的一方。下注对子时，对子的限红是庄对子+闲对子的总限红。也就是当显示对子限红为50-500时，
                                庄、闲对子总下注达到50即符合最低下注要求，庄、闲对子总下注最多只能是500，否则将超出最高限红，不
                                能成功下注。现场庄家、闲家投注注码最大者有权自行、指定或者由荷官揭开底牌，根据各赌场规则不同，揭牌权有不同的最低注码要求;庄家、闲家各最大投注注码差距过大，投注注码较小的一方也将不能获得揭牌权。
                            </p>
                            <p class="TieleP">三、发牌与点数计算</p>
                            <p>
                                百家乐一般用8副牌，洗牌后8副牌放在牌靴内。庄、闲双方每局均会收到至少两张牌，最多三张。第一及第三张牌发给闲家，第二及第四张牌发给庄家。玩家可下注庄或闲，根据各赌场规则不同，赌场限制玩家不能同时下注庄、闲。庄、闲两方前两张牌点数符合博牌规则时，将对需增牌一方（或两方同时）进行增牌（或
                                称作博牌)。具体博牌规则参照【博牌规则】。在点数计算方面，Ace的扑克牌被算作1点，2到9的扑克牌依点数不变，其他算作0点。当所有牌的点数总和超过9点时，则只算总数中的个位。因此最大可能的点数为9
                                点，最小为0点。
                            </p>
                            <p class="TieleP">四、博牌规则</p>
                            <table border="1" cellspacing="0" cellpadding="0" class="font22">
                                <thead>
                                <tr>
                                    <th>两张牌点数合计</th>
                                    <th>闲</th>
                                    <th>庄</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>0</td>
                                    <td>补一张牌</td>
                                    <td>补一张牌</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>补一张牌</td>
                                    <td>补一张牌</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>补一张牌</td>
                                    <td>补一张牌</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>补一张牌</td>
                                    <td>如闲家补得第三张牌是8,不须补牌;其它须补牌</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>补一张牌</td>
                                    <td>如闲家补得第三张牌是0.1.8.9,不须补牌;其它须补牌</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>补一张牌</td>
                                    <td>如闲家补得第三张牌是0.1.2.3.8.9,不须补牌;其它须补牌</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>不须张牌</td>
                                    <td>如闲家补得第三张牌是0.1.2.3.4.5.8.9,不须补牌;其它须补牌</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>不须张牌</td>
                                    <td>不须张牌</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>天牌，庄也不能补牌</td>
                                    <td>天牌，闲也不能补牌</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>天牌，庄也不能补牌</td>
                                    <td>天牌，闲也不能补牌</td>
                                </tr>
                                </tbody>
                            </table>
                            <p class="TieleP">五、赔率</p>
                            <table border="1" cellspacing="0" cellpadding="0" class="font22">
                                <thead>
                                <tr>
                                    <th>下注区域</th>
                                    <th>赔率</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>庄</td>
                                    <td>1赔0.95</td>
                                </tr>
                                <tr>
                                    <td>闲</td>
                                    <td>1赔1</td>
                                </tr>
                                <tr>
                                    <td>和</td>
                                    <td>1赔8</td>
                                </tr>
                                <tr>
                                    <td>庄对</td>
                                    <td>1赔11</td>
                                </tr>
                                <tr>
                                    <td>闲对</td>
                                    <td>1赔11</td>
                                </tr>
                                </tbody>
                            </table>
                            <p class="TieleP">六、其他补充说明</p>
                            <p>1.如遇故障因素将注销所有相关注单/奖金，退回注金。</p>
                            <p>2.如遇公布结果错误，将按照当期视频结果重新结算。</p>
                            <p>3.其他游戏相关问题请参照争议处理协议。</p>
                            <p>4.每局游戏局开庄、闲、和几率分别为0.458597、0.446247、0.095156。</p>
                        </template>
                        <template v-if="CurrentIndex === 1">
                            <p class="TieleP">一、简介</p>
                            <p>龙虎斗是当今世界各地赌场常见的赌戏之一，通过对比两张牌面的大小来定输赢。</p>
                            <p class="TieleP">二、投注&玩法</p>
                            <p>游戏使用8副扑克牌（每副扑克牌52张，无大小王），游戏排数合计416张。每局游戏开始荷官先削掉一张牌后，在龙、虎两门各牌一只牌，即龙与虎。</p>
                            <p>A最小，K最大;如遇两张牌点数一样，则比花色，花色大小顺序依次为︰黑桃、红桃、梅花、方块;相同点数、相同花色即为和局。</p>
                            <p>您有3种下注选择:@龙R虎@和局。</p>
                            <p class="TieleP">三、发牌与点数计算</p>
                            <p>
                                龙虎一支牌靴8副牌，无大小王，游戏牌数合计416张，洗牌后8副牌放在牌靴内。第一张牌发给龙，第二张牌发给虎。玩家可下注龙或虎，根据各赌场规则不同，赌场限制玩家不能同时下注龙、虎。在输赢计算方面，先比大小，再比花色。Ace的扑克牌被算作1点，2到10的扑克牌依点数不变，J算作11点，Q算作12点，K算作13点。牌面点数大即赢的一方。当遇到牌面点数一样时，则开始比牌面花色，花色从大到小顺序依次为:黑桃、红心、梅花、方块。
                            </p>
                            <p class="TieleP">四、赔率</p>
                            <table border="1" cellspacing="0" cellpadding="0" class="font22">
                                <thead>
                                <tr>
                                    <th>下注区域</th>
                                    <th>赔率</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>龙</td>
                                    <td>1赔0.97</td>
                                </tr>
                                <tr>
                                    <td>虎</td>
                                    <td>1赔0.97</td>
                                </tr>
                                <tr>
                                    <td>和</td>
                                    <td>1赔{{DefaultConfig.LoginTemplate === 'szwjt' ? 30 : 8}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                        <template v-if="CurrentIndex === 2">
                            <p class="TieleP">一、简介</p>
                            <p>
                                牛牛又名斗牛，是一款流行于广东、广西、湖南以及江浙一带的传统棋牌游戏。其中一家为庄家，其余为闲家，发完牌后马上开牌比牌，所有闲家与庄家一一进行比较确定输赢。游戏节奏紧凑刺激，规则简单。</p>
                            <p class="TieleP">二、投注&玩法</p>
                            <p>
                                牛牛中将根据头牌决定的顺序发四份牌&lt;庄家&gt;、&lt;闲1&gt;、&lt;闲2&gt;、&lt;闲3&gt;，玩家可押注闲家1-3的[平倍]、[翻倍]、[超级]</p>
                            <p>若押注闲家并且所押注闲家牌型大于庄家，则玩家获胜；</p>
                            <p>平倍：闲家与庄家平倍博弈，派彩不受牌型影响，按平倍赔率计算</p>
                            <p>翻倍：闲家与庄家翻倍博弈，派彩根据胜方牌型，按翻倍赔率计算</p>
                            <p>超级：闲家与庄家翻倍博弈，派彩根据胜方牌型，按超级赔率计算</p>
                            <p class="TieleP">三、游戏玩法</p>
                            <p>
                                游戏使用扑克牌中52张牌(不含鬼牌)，每局每家派发5张牌，先从5张牌中找出3张可成10或10的倍数的组合，剩余2张牌相加，
                                如遇点数相加超过10点则减去10，为改局之点数，与庄家比点数大小决定胜负。
                            </p>
                            <p>你有以下几种下注选择:</p>
                            <p>『闲一』:平倍、翻倍、超级。</p>
                            <p>『闲二』:平倍、翻倍、超级。</p>
                            <p>『闲三』:平倍、翻倍、超级。</p>
                            <p>发牌顺序</p>
                            <p>1.头牌:荷官每局开始会先开'头牌'，依据头牌点数(从庄家方位逆时针方向算起)决定开牌位置。</p>
                            <p>2.牌型</p>
                            <p>牛牛 > 牛9 > 牛8 > 牛7 > 牛6 > 牛5 > 牛4 > 牛3 > 牛2 > 牛1 > 无牛</p>
                            <p>牌彩赔率</p>
                            <p>
                                牌型 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平倍赔率&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;翻倍赔率
                            </p>
                            <p>
                                牛牛 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1:0.97 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:2.91
                            </p>
                            <p>
                                牛9-牛7 &nbsp;&nbsp;&nbsp;&nbsp; 1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:1.94
                            </p>
                            <p>一般牌型 &nbsp;&nbsp;&nbsp; 1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:0.97</p>
                            <p>超级赔率</p>
                            <p>按照牌型计算，牛牛赔10倍，牛9赔9倍，依次类推 无牛赔1倍</p>
                            <p>1.桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。玩家在每个玩法的可押注限额是玩法限红与玩家个人限红的交集。</p>
                            <p>2.如遇结算错误，按照当期视频结果重新结算</p>
                        </template>
                        <template v-if="CurrentIndex === 3">
                            <p class="TieleP">一、赔率</p>
                            <p>大1:0.97 &nbsp;&nbsp;&nbsp;小1:0.97 &nbsp;&nbsp;&nbsp;同1:8</p>
                            <p class="TieleP">二、游戏说明</p>
                            <p>大11至17 &nbsp;&nbsp;&nbsp;小4至10 &nbsp;&nbsp;&nbsp;同为三个1或三个6</p>
                        </template>
                        <template v-if="CurrentIndex === 4">
                            <p class="TieleP">一、使用牌类</p>
                            <p>1-9筒*4，幺鸡*4，共计40张牌</p>
                            <p class="TieleP">二、发牌规则</p>
                            <p>开局已第一张牌点数定位，已牌的点数从筒一开始计算</p>
                            <p class="TieleP">三、如何胜出</p>
                            <p>推筒子中将根据头牌决定的顺序发四分牌&lt;庄家&gt;、&lt;闲1&gt;、&lt;闲2&gt;、&lt;闲3&gt;、&lt;闲4&gt;，玩家可押注闲家1-4中任何一家且所押闲家牌小型大于庄家，则本家玩家获胜，反之则庄家获胜。</p>
                            <p>平倍：闲家与庄家博弈，派彩不受牌型影响，按平倍赔率计算</p>
                            <p>翻倍：闲家与庄家博弈，派彩不受牌型影响，按翻倍赔率计算</p>
                            <p>超级：闲家与庄家博弈，派彩不受牌型影响，按超级赔率计算</p>
                            <p>牌点点数:</p>
                            <p>1-9筒对应各自的点数相加，幺鸡为0.5点比较大小。</p>
                            <p>每一方将两张牌的点数相加，取其个位数字，然后直接比大小，每一个闲家跟庄家比输赢。</p>
                            <p>(注:当庄家与闲家同时出现0点时庄家赢)</p>
                            <p>赔率 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平倍赔率
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;翻倍赔率</p>
                            <p>
                                对子 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:2.91
                            </p>
                            <p>
                                7至9点半 &nbsp;&nbsp;&nbsp;&nbsp;1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:1.94
                            </p>
                            <p>
                                一般牌型 &nbsp;&nbsp;&nbsp;&nbsp; 1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:0.97
                            </p>
                            <p>超级赔率按照牌型计算，对子赔10倍，9点赔9倍，依次类推 1点赔1倍</p>
                            <p>1.桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。玩家在每个玩法的可押注限额是玩法限红与玩家个人限红的交集。</p>
                            <p>2.如遇结算错误，按照当期视频结果重新结算</p>
                        </template>
                        <template v-if="CurrentIndex === 5">
                            <p class="TieleP">一、游戏玩法</p>
                            <p>
                                单双骰子只有2个投注项:单或双。骰盅里有2颗骰子，荷官摇宝后，两颗骰子点数之和为奇数时，此宝为单赢，和为偶数时，此宝为双赢，客人先下注单或双，也可单双同时下注，计时器为0后荷官摇宝，并将骰子点数输入电脑作为此宝判断输赢。
                            </p>
                            <p class="TieleP">二、赔率</p>
                            <p>赢家赔率为1赔0.97</p>
                            <p class="TieleP">三、无效宝</p>
                            <p>当骰盅内的骰子出现叠子和翘子时，此宝为无效宝，此时荷官不输入点面结果，而是展示无效5秒，随即重新摇宝。</p>
                        </template>
                        <template v-if="CurrentIndex === 6">
                            <p class="TieleP">一、炸弹玩法</p>
                            <p>1.三张同样的牌为炸弹牌，炸弹的大小顺序是333,KKK,QQQ,JJJ,以此类推到AAA。</p>
                            <p>2.JQK属于任何三张加起来都是三公，三张牌一样比黑，红，梅，方。</p>
                            <p>3.9加黑桃K红桃K为点数最大以此类推整点（0)最小442一样的牌比花(黑红梅方)。</p>
                            <p>4.倍数:炸弹(333)三公(JQK)为三倍点数789为两倍6点以下为一倍，客人赢钱平倍翻倍抽水3%。</p>
                            <p class="TieleP">二、牌型以大到小</p>
                            <p>1.牌型大小大三公>小三公>湿三公>点数大小，三公牌的大小顺序为333，KKK, QQQ，JJJ,以此类推AAA。</p>
                            <p>2.花色大小黑桃>红桃>梅花>方块。</p>
                            <p>3.点数牌比在小:先比点数大小，再比公牌数，(点数相同的双公牌数大于单公牌数，公牌数相同，比单张公数大小，花色)</p>
                            <p class="TieleP">三、牌手操作失误，当局作废，本公司不实行通赔制度。</p>
                        </template>
                    </div>
                    <div v-else class="absolute border-box allw">
                        <template v-if="CurrentIndex === 0">
                            <p class="TieleP">One、Giới thiệu sơ lược</p>
                            <p>Baccarat là một trong những trò chơi bài lá phổ biến trong các sòng bạc. Đây là trò chơi
                                cá cược được yêu thích nhất trong các sòng bạc trên toàn thế giới hiện nay.</p>
                            <p class="TieleP">Two、Đặt cược và cách chơi</p>
                            <p>Nếu người chơi cho rằng số điểm cuối cùng của nhà cái và người chơi bằng nhau, bất phân
                                thắng bại, có thể đặt cược Ván hòa; nếu người chơi cho rằng nhà cái hoặc người chơi
                                (hoặc cả hai bên) sẽ có một cặp bài trùng (hai lá bài cùng số điểm), có thể đặt cược Cặp
                                đôi; nếu người chơi cho rằng nhà cái hoặc người chơi sẽ có điểm cuối cùng cao hơn, có
                                thể đặt cược vào bên mà mình cho rằng sẽ có điểm số cao hơn bên còn lại. Khi đặt cược
                                Cặp đôi, lằn ranh đỏ của Cặp đôi là tổng Lằn ranh đỏ của cặp đôi của nhà cái + cặp đôi
                                bên người chơi. Nghĩa là, khi lằn ranh đỏ cặp đôi được hiển thị là 50-500, tổng số tiền
                                đặt cược của nhà cái và người chơi đạt 50 để đáp ứng yêu cầu đặt cược tối thiểu. Tổng số
                                tiền đặt cược của nhà cái và người chơi chỉ được tối đa là 500, nếu vượt mức tối đa sẽ
                                bị vượt quá lằn ranh đỏ, dẫn đến việc không thể đặt cược thành công. Nhà cái hoặc người
                                chơi có số tiền đặt cược lớn nhất có quyền tự lât lá bài, chỉ định hoặc lật bởi người
                                chia bài. Mỗi sòng bạc khác nhau có nguyên tắc khác nhau, quyền lật lá bài có các yêu
                                cầu đặt cược tối thiểu khác nhau; chênh lệch đặt cược tối đa giữa nhà cái và người chơi
                                lớn thì bên đặt cược l nhỏ hơn sẽ không có quyền lật bài.
                            </p>
                            <p class="TieleP">Three、PhÁt bài và tính điểm</p>
                            <p>
                                Baccarat thường sử dụng 8 bộ bài, sau khi trộn bài xong, 8 bộ bài được đặt vào trong bốt
                                bài. Cả nhà cái và người chơi sẽ được nhận ít nhất hai lá trong mỗi ván, tối đa là ba
                                lá. Lá bài đầu tiên và thứ ba được chia cho người chơi, lá bài thứ hai và thứ tư được
                                chia cho nhà cái. Người chơi có thể đặt cược vào nhà cái hoặc người chơi Theo quy định
                                của mỗi sòng bạc, sòng bạc không cho phép người chơi đặt cược vào cả nhà cái và người
                                chơi cùng một lúc. Khi 2 lá bài đầu tiên của nhà cái và người chơi phù hợp luật chơi thì
                                người chơi cần rút thêm bài (hoặc cả 2 bên đều rút). Vui lòng tham khảo [Luật chơi bài]
                                để biết các quy tắc trò chơi cụ thể. Về cách tính điểm, quân bài Át chủ bài được tính là
                                1 điểm, 2-9 tính điểm như thường, các lá bài còn lại được tính là 0 điểm. Khi tổng điểm
                                của tất cả các lá bài vượt quá 9 điểm, thì chỉ tính số sau trong tổng điểm. Do đó, số
                                điểm tối đa là 9 điểm và tối thiểu là 0 điểm.
                            </p>
                            <p class="TieleP">Four、Luật chơi bài</p>
                            <table border="1" cellspacing="0" cellpadding="0" class="font22">
                                <thead>
                                <tr>
                                    <th>Tổng số điểm của 2 lá bài</th>
                                    <th>Người chơi</th>
                                    <th>Nhà cái</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>0</td>
                                    <td>Rút thêm 1 lá</td>
                                    <td>Rút thêm 1 lá</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Rút thêm 1 lá</td>
                                    <td>Rút thêm 1 lá</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Rút thêm 1 lá</td>
                                    <td>Rút thêm 1 lá</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Rút thêm 1 lá</td>
                                    <td>Nếu người chơi rút lá thứ ba là 8, thì không cần rút thêm, các trường hợp khác
                                        cần rút thêm
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Rút thêm 1 lá</td>
                                    <td>Nếu người chơi rút lá thứ ba là 0.1.8.9, thì không cần rút thêm, các trường hợp
                                        khác cần rút thêm
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Rút thêm 1 lá</td>
                                    <td>Nếu người chơi rút lá thứ ba là 0.1.2.3.8.9, thì không cần rút thêm, các trường
                                        hợp khác cần rút thêm
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Không cần rút thêm</td>
                                    <td>Nếu người chơi rút lá thứ ba là 0.1.2.3.4.5.8.9, thì không cần rút thêm, các
                                        trường hợp khác cần rút thêm
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Không cần rút thêm</td>
                                    <td>Không cần rút thêm</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Bài trùm, nhà cái không được rút thêm</td>
                                    <td>Bài trùm, người chơi không được rút thêm</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Bài trùm, nhà cái không được rút thêm</td>
                                    <td>Bài trùm, người chơi không được rút thêm</td>
                                </tr>
                                </tbody>
                            </table>
                            <p class="TieleP">Five、Tỷ lệ cược</p>
                            <table border="1" cellspacing="0" cellpadding="0" class="font22">
                                <thead>
                                <tr>
                                    <th>Mục đặt cược</th>
                                    <th>Tỷ lệ cược</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Nhà cái</td>
                                    <td>1 cược 0.95</td>
                                </tr>
                                <tr>
                                    <td>Người chơi</td>
                                    <td>1 cược 1</td>
                                </tr>
                                <tr>
                                    <td>Hòa</td>
                                    <td>1 cược 8</td>
                                </tr>
                                <tr>
                                    <td>Nhà cái có đôi</td>
                                    <td>1 cược 11</td>
                                </tr>
                                <tr>
                                    <td>Người chơi có đôi</td>
                                    <td>1 cược 11</td>
                                </tr>
                                </tbody>
                            </table>
                            <p class="TieleP">Six、Các hướng dẫn bổ sung khác</p>
                            <p>1.Trường hợp xảy ra sự cố, tất cả các lệnh đặt cược / tiền thưởng liên quan sẽ bị hủy,
                                tiền đặt cọc sẽ được trả lại.</p>
                            <p>2.Trường hợp có sai sót trong kết quả đã công bố, sẽ quyết toán lại theo kết quả video kỳ
                                hiện tại.</p>
                            <p>3.Đối với các vấn đề khác liên quan đến trò chơi, vui lòng tham khảo thỏa thuận giải
                                quyết tranh chấp.</p>
                            <p>4.Tỷ lệ cược của nhà cái, người chơi và tỷ lệ hòa cho mỗi ván lần lượt là
                                0.458597、0.446247 và 0.095156.</p>
                        </template>
                        <template v-if="CurrentIndex === 1">
                            <p class="TieleP">One、Giới thiệu sơ lược</p>
                            <p>Rồng hổ là một trong những trò chơi đánh bạc phổ biến trong các sòng bạc trên toàn thế
                                giới hiện nay, thắng thua được xác định bằng cách so sánh hai lá bài.</p>
                            <p class="TieleP">Two、Đặt cược và cách chơi</p>
                            <p>Trò chơi sử dụng 8 bộ bài (52 lá bài mỗi bộ, không có quân bài Át), tổng số lá bài trong
                                trò chơi là 416 lá.Khi bắt đầu mỗi ván chơi, người chia bài sẽ rút một lá trước, sau đó
                                đặt một lá ở ở mỗi cửa rồng và hổ, là Rồng và Hổ.</p>
                            <p>A là nhỏ nhất, K là lớn nhất; nếu hai lá có cùng số điểm, thì so sánh chất của lá bài,
                                thứ tự chất các lá bài là: bích ,cơ, chuồn, rô; cùng số điểm và cùng chất thì đó là ván
                                hòa .</p>
                            <p>Bạn có 3 lựa chọn để đặt cược: @ Rồng @ Hổ @ Ván hòa.</p>
                            <p class="TieleP">Three、PhÁt bài và tính điểm</p>
                            <p>
                                Rồng Hổ có 8 bộ bài trong một bốt, không có quân Át, Tổng số lá bài trong trò chơi là
                                416 lá. Sau khi xáo bài, 8 bộ bài được đặt vào trong bốt. Lá đầu tiên được chia cho
                                rồng, lá thứ hai được chia cho hổ. Người chơi có thể đặt cược vào Rồng hoặc Hổ. Mỗi sòng
                                bạc có quy tắc khác nhau, sòng bạc không cho phép người chơi đặt cược vào cả Rồng và Hổ
                                cùng một lúc. Trong việc tính toán thắng thua, trước tiên sẽ so sánh điểm và sau đó là
                                chất. Các lá bài A được tính là 1 điểm, lá bài từ 2 đến 10 tính điểm như thường. J được
                                tính là 11 điểm, Q được tính là 12 điểm và K được tính là 13 điểm. Bên thắng là bên có
                                số điểm lớn hơn. Khi các quân bài có điểm giống nhau, thì sẽ so sánh chất bài, thứ tự
                                chất bài là : bích, cơ, chuồn và rô.
                            </p>
                            <p class="TieleP">Four、Tỷ lệ cược</p>
                            <table border="1" cellspacing="0" cellpadding="0" class="font22">
                                <thead>
                                <tr>
                                    <th>Mục đặt cược</th>
                                    <th>Tỷ lệ cược</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Rồng</td>
                                    <td>1 cược 0.97</td>
                                </tr>
                                <tr>
                                    <td>Hổ</td>
                                    <td>1 cược 0.97</td>
                                </tr>
                                <tr>
                                    <td>Hòa</td>
                                    <td>1 cược {{DefaultConfig.LoginTemplate === 'szwjt' ? 30 : 8}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                        <template v-if="CurrentIndex === 2">
                            <p class="TieleP">One、Giới thiệu sơ lược</p>
                            <p>
                                Ngưu Ngưu, còn được gọi là Đấu bò, là một trò chơi cờ bài truyền thống phổ biến ở Quảng
                                Đông, Quảng Tây, Hồ Nam, Giang Tô và Chiết Giang. Trong đó có một nhà cái và những người
                                còn lại là người chơi, sau khi chia bài xong sẽ lật bài và so sánh, tất cả người chơi sẽ
                                so sánh nhà cái để phân định thắng thua. Trò chơi diễn ra nhanh chóng và thú vị, luật
                                chơi đơn giản.</p>
                            <p class="TieleP">Two、Đặt cược và cách chơi</p>
                            <p>
                                Thứ tự phÁt 4 lá bài trong trò Ngưu Ngưu được xác định bởi lá bài đầu tiên: &lt;Nhà cái&gt;、&lt;Người
                                chơi 1&gt;、&lt;Người chơi 2&gt;、&lt;Người chơi 3&gt;, người chơi có thể đặt cược vào các
                                người chơi từ 1-3 Bằng]、[Gấp đôi]、[Cấp số nhân].</p>
                            <p>Nếu bạn đặt cược vào người chơi và bài của người chơi lớn hơn nhà cái, người chơi sẽ
                                thắng;</p>
                            <p>Bằng: Người chơi và nhà cái cược hòa, tiền cược không bị ảnh hưởng bởi loại bài, tính tỷ
                                lệ cược theo ván hòa</p>
                            <p>Gấp đôi: Người chơi và nhà cái cược nhân đôi, tiền cược được tính theo loại bài của thắng
                                và tỷ lệ cược tính nhân đôi</p>
                            <p>Cấp số nhân: Người chơi và nhà cái cược nhân đôi, tiền cược được tính theo loại bài bên
                                thắng, tỷ lệ cược cấp số nhân</p>
                            <p class="TieleP">Three、Cách chơi</p>
                            <p>
                                Trò chơi sử dụng 52 lá trong bộ bài (không bao gồm quân bài Át), mỗi ván mỗi bên được
                                chia 5 lá bài. Đầu tiên, tìm bộ ba là bội số của 10 hoặc 10 từ 5 lá, rồi cộng 2 lá còn
                                lại.Chẳng hạn như nếu số điểm cộng vượt quá 10 điểm sẽ bị trừ đi 10, để tính vào bộ sau.
                                So sánh điểm số với nhà cái để quyết định thắng thua.
                            </p>
                            <p>Bạn có các lựa chọn đặt cược sau:</p>
                            <p>"Người chơi 1": Bằng, Gấp đôi, Cấp số nhân.</p>
                            <p>"Người chơi 2": Bằng, Gấp đôi, Cấp số nhân.</p>
                            <p>"Người chơi 3": Bằng, Gấp đôi, Cấp số nhân.</p>
                            <p>Thứ tự phÁt bài</p>
                            <p>1.Lá bài đầu tiên: Người phát bài sẽ lật bài trước gọi là ' lá đầu tiên' vào mỗi ván, xác
                                định vị trí mở bài dựa trên số điểm của lá bài đầu tiên (tính theo chiều ngược chiều kim
                                đồng hồ từ vị trí của nhà cái).</p>
                            <p>2.Loại bài</p>
                            <p>Ngưu ngưu> Ngưu 9> Ngưu 8> Ngưu 7> Ngưu 6> Ngưu 5> Ngưu 4> Ngưu 3> Ngưu 2> Ngưu 1> Không
                                ngưu</p>
                            <p>Tỷ lệ cược bài</p>
                            <p>
                                Loại bài&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tỷ lệ cược bằng&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tỷ
                                lệ cược gấp đôi
                            </p>
                            <p>
                                Ngưu Ngưu &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1:0.97 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:2.91
                            </p>
                            <p>
                                Ngưu 9- Ngưu 7 &nbsp;&nbsp;&nbsp;&nbsp; 1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:1.94
                            </p>
                            <p>Loại bài thông thường &nbsp;&nbsp;&nbsp; 1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:0.97</p>
                            <p>Tỷ lệ cược cấp số nhân</p>
                            <p>Tính loại bài, Ngưu ngưu cược 10 lần, Ngưu 9 cược 9 lần, tương tự vậy Không Ngưu cược 1
                                lần.</p>
                            <p>1.Giới hạn đỏ của bàn là Giới hạn đỏ tối thiểu và tối đa trong cách chơi chính của trò
                                chơi bàn này. Giới hạn đặt cược của người chơi cho mỗi trò chơi là giao điểm của giới
                                hạn trò chơi và giới hạn cá nhân của người chơi.</p>
                            <p>2.Trong trường hợp quyết toán xảy ra sai sót, quyết toán lại theo kết quả video kỳ hiện
                                tại</p>
                        </template>
                        <template v-if="CurrentIndex === 3">
                            <p class="TieleP">One、Tỷ lệ cược</p>
                            <p>Lớn1:0.97 &nbsp;&nbsp;&nbsp;Nhỏ1:0.97 &nbsp;&nbsp;&nbsp;Bằng1:8</p>
                            <p class="TieleP">Two、Mô tả trò chơi</p>
                            <p>Lớn 11 đến 17 Nhỏ 4 đến 10 Bằng là ba lá 1 hoặc ba lá 6</p>
                        </template>
                        <template v-if="CurrentIndex === 4">
                            <p class="TieleP">One、Loại bài sử dụng</p>
                            <p>Văn 1-9 * 4, Nhất sách* 4, Tổng cộng 40 con</p>
                            <p class="TieleP">Two、Quy tắc cấp phép</p>
                            <p>Bắt đầu ván chơi dựa trên quân bài đầu tiên, số điểm được tính quân bài đầu tiên</p>
                            <p class="TieleP">Three、Làm thế nào để giành chiến thắng</p>
                            <p>Bốn quân bài sẽ được chia theo thứ tự được xác định bởi quân bài đầu tiên trong ống. &lt;Nhà
                                cái&gt;、 &lt;Người chơi 1&gt;、 &lt;Người chơi 2&gt;、 &lt;Người chơi 3&gt; và &lt;Người
                                chơi 4&gt; Người chơi có thể đặt cược vào các người chơi từ 1-4 . Nếu cược người chơi có
                                bài lớn hơn nhà cái, thì người chơi thắng, ngược lại nhà cái thắng.</p>
                            <p>Bằng: Cược người chơi và nhà cái, tiền cược không bị ảnh hưởng bởi loại bài, tính tỷ lệ
                                cược theo tỷ lệ hòa</p>
                            <p>Gấp đôi: Cược người chơi và nhà cái, tiền cược không bị ảnh hưởng bởi loại bài, tính tỷ
                                lệ cược nhân đôi </p>
                            <p>Cấp số nhân: Cược người chơi và nhà cái, tiền cược không bị ảnh hưởng bởi loại bài, tính
                                tỷ lệ cược theo cấp số nhân</p>
                            <p>Tính điểm trên quân bài:</p>
                            <p>Văn 1-9 tương ứng với các điểm tương ứng cộng với nhau, gà là 0,5 điểm để so sánh điểm
                                lớn nhỏ.</p>
                            <p>Mỗi bên cộng điểm của hai quân bài lại với nhau, lấy số sau, sau đó so sánh trực tiếp. So
                                sánh người chơi với nhà cái xem bên nào thắng.</p>
                            <p>(Lưu ý: Khi nhà cái và người chơi xuất hiện cùng lúc 0 điểm, thì nhà cái thắng)</p>
                            <p>Tỷ lệ cược Tỷ lệ cược bằng Tỷ lệ cược gấp đôi</p>
                            <p>
                                Cặp đôi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:2.91
                            </p>
                            <p>
                                7 đến 9:30 &nbsp;&nbsp;&nbsp;&nbsp;1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:1.94
                            </p>
                            <p>
                                Loại bài phổ thông &nbsp;&nbsp;&nbsp;&nbsp; 1:0.97&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:0.97
                            </p>
                            <p>Tỷ lệ cược cấp số nhân được tính theo loại bài, Cặp đôi 10 lần, 9 điểm 9 lần, tương tự
                                vậy 1 điểm 1 lần.</p>
                            <p>1.Giới hạn đỏ của bàn là giới hạn đỏ tối thiểu và tối đa của cách chơi chính của bàn trò
                                chơi này. Giới hạn đặt cược của người chơi cho mỗi trò chơi là giao điểm của giới hạn
                                trò chơi và giới hạn cá nhân của người chơi.</p>
                            <p>2.Trong trường hợp xảy ra quyết toán sai sót, sẽ quyết toán lại theo kết quả video kỳ
                                hiện tại.</p>
                        </template>
                        <template v-if="CurrentIndex === 5">
                            <p class="TieleP">One、Cách chơi</p>
                            <p>
                                Chỉ có 2 mục cá cược trên xúc xắc lẻ và chẵn: lẻ hoặc chẵn. Có 2 viên xúc xắc trong đĩa
                                xúc xắc, sau khi người lắc xúc xắc, nếu tổng điểm của hai viên xúc xắc là số lẻ thì kết
                                quả thắng đăt lẻ thắng, còn khi tổng là số chẵn thì đặt số chẵn thắng. Người chơi có thể
                                đặt cược lẻ hoặc chẵn, hoặc đặt cả lẻ và chẵn. Sau khi đồng hồ đếm thời gian về 0, người
                                lắc xúc xắc và nhập các điểm xúc xắc vào máy tính để quyết định thắng hay thua.
                            </p>
                            <p class="TieleP">Two、Tỷ lệ cược</p>
                            <p>Tỷ lệ thắng cược là 1 cược 0.97</p>
                            <p class="TieleP">Three、Xúc xắc không hợp lệ</p>
                            <p>Khi xúc xắc trong đĩa xúc xắc xếp chồng lên nhau và cong vênh thì xúc xắc không hợp lệ,
                                lúc này người lắc xúc xắc sẽ không nhập kết quả lên máy tính, mà hiển thị kết quả không
                                hợp lệ trong 5 giây rồi lắc lại xúc xắc.</p>
                        </template>
                        <template v-if="CurrentIndex === 6">
                            <p class="TieleP">One、Cách chơi Sâm lốc</p>
                            <p>1.Ba quân giống nhau là sâm lốc và thứ tự lớn nhỏ là 333, KKK, QQQ, JJJ, tương tự như vậy
                                cho đến AAA.</p>
                            <p>2.JQK ba thẻ bất kỳ cộng lại là Sâm lốc , ba lá giống nhau thì so sánh đen đỏ, cơ rô bích
                                chuồn.</p>
                            <p>3.9 cộng với quân K đen cơ K đỏ cơ, quân bài lớn nhất, và tương tự cứ như thế, toàn bộ
                                điểm (0), tối thiểu 442 bài giống nhau thì so về chất (đen đỏ, cơ rô bích chuồn).</p>
                            <p>4.Hệ số nhân: Sâm lốc (333) Sâm lốc (JQK) là gấp ba lần, 789 nhân hai, dưới 6 điểm gấp
                                đôi, người chơi thắng tiền theo hệ số nhân 3%.</p>
                            <p class="TieleP">Two、Loại bài từ lớn đến nhỏ</p>
                            <p>1.Thứ tự lớn nhở của bài là : Sâm lốc lớn> Sâm lốc nhỏ> Thấp Sâm lốc > , thứ tự là 333,
                                KKK, QQQ, JJJ, cứ như vậy cho đến AAA.</p>
                            <p>2.Thứ tự chất : Bích > Cơ> Chuồn> Rô</p>
                            <p>3.Tỷ lệ điểm bài: trước tiên so sánh số điểm, sau đó là số Sâm lốc, (số đôi Sâm lốc có
                                cùng điểm lớn hơn số Sâm lốc đơn, số Sâm lốc giống nhau, thì so sánh lớn nhỏ và chất của
                                Sâm lốc đơn).</p>
                            <p class="TieleP">Three、Người phát bài mắc sai lầm trong thao tác, thì coi như ván vô hiệu. Công
                                ty không thực hiện chế độ bồi thường.</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'GameRules',
        computed: {...mapState(['ShowGameRules', 'whdef', 'DefaultConfig', 'LangeuageAgent'])},
        data() {
            return {
                TitleArr: ['BJL', 'LH', 'NN', 'DXD', 'TTZ', 'DS', 'SG'],//'百家乐', '龙虎', '牛牛', '大小点', '推筒子', '单  双', '三  公'
                CurrentIndex: 0,
                ContentArr: ['']
            }
        },
        watch: {
            'CurrentIndex'() {
                let TransFormDom = this.$refs["WhellDivRules"].children[0];
                TransFormDom.style.transform = `translate(0,0px)`;
            }
        },
    }
</script>

<style lang="less">
    .GameRules {
        z-index: 100;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);

        .setbox {
            width: 13.5rem;
            height: 9rem;
            background-color: rgba(0, 0, 0, .6);
            border: solid 1px #8e7056;

            > div:first-child {
                width: 4.2rem;
                height: .35rem;
                background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;

                img:last-child {
                    right: .2rem;
                    width: .3rem;
                    height: .3rem;
                }
            }

            > div:last-child {
                border-top: solid 1px #8e7056;
                background-color: #000;
                padding: 0 .3rem 0 .3rem;

                .TitleBox {
                    margin: .3rem 0;

                    > p {
                        width: 1.5rem;
                        height: .5rem;
                        line-height: .5rem;
                        color: #c5c5c5;
                        background-color: #3a3a3a;
                        border-radius: 5px;

                        &.OnHover, &:hover {
                            background: url("../../../public/img/Common/queren_btn_bg.png") no-repeat top left/cover;
                            color: #291c03;
                        }
                    }
                }

                .ContentBox {
                    background-color: #201f1f;
                    border: solid 1px #8e7056;
                    font-size: .26rem;

                    div {
                        padding: .3rem;
                        transition: .3s;
                        top: 0;
                        left: 0;
                        color: #9b9b9b;

                        > p {
                            margin-bottom: .2rem;

                            &.TieleP {
                                font-size: .3rem;
                                color: #b18265;
                            }
                        }

                        table {
                            width: 100%;
                            text-align: center;
                            border-color: #B18265;
                            margin-bottom: .2rem;

                            thead {
                                background-color: #B18265;
                                color: #F3B38C;
                                line-height: .6rem;
                                border: none;

                                th {
                                    border: none;
                                }
                            }

                            tbody {
                                td {
                                    line-height: .35rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
