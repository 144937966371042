<template>
    <div v-if="ShowPassword" class="SetPassWords fixed allw allh">
        <div class="setbox absolute position-center flex column relative">
            <div class="margin-auto mat15 mab15 flex items-center content-center">
                <img class="allh"
                     :src="'./img/Common/title_xgmm'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">
                <img @click="CloseDialog" class="absolute pointer" src="../../../public/img/Common/gn_gb.png" alt="">
            </div>
            <div class="flex1 flex column">
                <div class="flex column content-center items-center flex1">
                    <div v-for="(item,index) in Groups" :key="index"
                         class="GroupsOne flex content-center items-center allw">
                        <span :class="[LangeuageAgent === 'CH' ? 'font36' : 'font18 NeedSet']"><font
                                v-if="item.title.length === 3 && LangeuageAgent === 'CH'"
                                class="visibility-hidden">占</font>{{Lang[item.title]}}：</span>
                        <input v-model="item.val" :placeholder="Lang[item.place]"
                               :class="[LangeuageAgent === 'CH' ? 'font26' : 'font18']" type="text">
                    </div>
                </div>
                <div class="canclebox font24 flex content-center items-center">
                    <span @click="DoModify" class="pointer text-center hover opacity8">{{Lang['QRXG']}}
                        <!--确认修改--></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {ModifyPass} from '../../Api';

    export default {
        name: 'SetPassWords',
        computed: {...mapState(['ShowPassword', 'LangeuageAgent'])},
        data() {
            return {
                Groups: [
                    {title: 'JMM', val: '', place: 'QSRJMM'},//旧密码    请输入旧密码
                    {title: 'XMM', val: '', place: 'QSRXMM'},//新密码    请输入新密码
                    {title: 'QRMM', val: '', place: 'QZCSRXMM'}//确认密码   请再次输入新密码
                ],
            }
        },
        methods: {
            DoModify() { //确认修改
                let IsStr = 0; //是否有验证消息
                let [{val: val1}, {val: val2}, {val: val3}] = this.Groups;
                if (val1 === '' || val2 === '' || val3 === '') IsStr = this.Lang['QSRMM'];  //请输入密码
                if (val1.length < 6 || val2.length < 6 || val3.length < 6) IsStr = this.Lang['6-20YWSZMM'];//密码不能少于6位
                if (val2 !== val3) IsStr = this.Lang['MMBYZ'];//两次密码不一致
                if (IsStr) {
                    this.Toast('2', IsStr);
                    return;
                }
                ModifyPass({oldPass: val1, newPass: val3}).then((resp) => {
                    if (resp.code === 0) {
                        this.CloseDialog();
                        this.Toast('1', this.Lang['XGCG'], {   //修改成功，即将返回登陆
                            CallBack: () => {
                                this.SocketApi.CloseSock().then(() => {
                                    sessionStorage.Heartbeat = false;
                                    this.$router.replace('/');
                                });
                            }
                        });
                        return;
                    }
                    this.Toast('2', resp.message);
                });
            },
            CloseDialog() {
                this.SetStore({
                    Prop: 'ShowPassword',
                    Val: false
                });
            },
        }
    }
</script>

<style lang="less">
    .SetPassWords {
        z-index: 100;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);

        .setbox {
            width: 9rem;
            height: 6rem;
            background-color: rgba(0, 0, 0, .6);
            border: solid 1px #8e7056;

            > div:first-child {
                width: 4.2rem;
                height: .35rem;
                background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;

                img:last-child {
                    right: .2rem;
                    width: .3rem;
                    height: .3rem;
                }
            }

            > div:last-child {
                border-top: solid 1px #8e7056;
                background-color: #000;

                .GroupsOne {
                    &:nth-child(2) {
                        margin-top: .35rem;
                        margin-bottom: .35rem;
                    }

                    span {
                        color: #e6c786;

                        &.NeedSet {
                            width: 1.8rem;
                            text-align: right;
                        }
                    }

                    input {
                        color: #666666;
                        width: 3.5rem;
                        height: .7rem;
                        background-color: #e2e1e1;
                        border-radius: 0.06rem;
                        padding-left: .15rem;
                        padding-right: .15rem;
                    }
                }

                .canclebox {
                    padding: 0 2.1rem 0 2.1rem;
                    margin: .4rem 0;

                    span {
                        height: .6rem;
                        width: 1.55rem;
                        line-height: .6rem;
                    }

                    span:first-child {
                        background: url("../../../public/img/Common/queren_btn_bg.png") no-repeat top center/cover;
                        color: #291c03;
                    }

                    span:last-child {
                        background: url("../../../public/img/Common/quxiao_btn_bg.png") no-repeat top center/cover;
                        color: #c5c5c5;
                    }
                }
            }
        }
    }
</style>
