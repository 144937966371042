class Calculation {
    constructor(roadList, gameType) {

    }

    road(roadList, isHall) {
        let gameType = isHall ? localStorage.HallName : localStorage.type;
        if (typeof isHall === 'string') gameType = isHall;
        let list = [];
        this.roadList = roadList === null ? [] : JSON.parse(JSON.stringify(roadList));
        if (gameType === "Baccarat" || gameType === "LonghuLake" || gameType === "SingleDouble" || gameType === "SameSize") list = this.Baccarat();
        if (gameType === "Cattle" || gameType === "ThreeCounsellors" || gameType === "PushBobbin") list = roadList;
        return list;
    }

    Baccarat() {
        // 百家乐:  是3个元素的数组,  第一个元素是代表:庄、和、闲,   如果第一位是0代表庄 ,第一位是1代表闲 ,第一位2代表和
        // 后面第二位是庄对:  庄对位置只会是3 ,   第三位是闲对,闲对位置只会是4
        // [0,-1,-1] 就表示是庄赢
        // [1, 3, 4]  表示  闲赢 庄对 闲对
        // [1,-1,4]  表示 闲赢 闲对
        let list = {
            "resultNumber": [0, 0, 0, 0, 0],
            "ZhuzaiRoad": [],
            "MainRoad": [],
            "PathRoad": [],
            "NumRoad": [],
            "ObliqueRoad": [],
            "YueyouRoad": [],
            "AskRoad": { //问路
                "ZhuzaiRoad": [],
                "MainRoad": [],
                "PathRoad": [],
                "NumRoad": [],
                "ObliqueRoad": [],
                "YueyouRoad": [],
            },
        };
        //初始化问路数据到列表里面
        if (this.roadList.length !== 0) this.roadList.push([0, "ask", ""]);
        let PathRoadYz = { //小路验证器
            PathRoad: {
                isBegin: false,  //是否开始 位置在[1,1] 或者 [2,0] 只存在其中一个
            },
            ObliqueRoad: {
                isBegin: false,  //是否开始 位置在[1,1] 或者 [2,0] 只存在其中一个
            },
            YueyouRoad: {
                isBegin: false,  //是否开始 位置在[1,1] 或者 [2,0] 只存在其中一个
            }
        };
        let countask = true;
        for (let index = 0; index < this.roadList.length; index++) {
            let item = this.roadList[index];
            let [a, b, c] = item;
            { //添加庄闲和数量
                let array = list["resultNumber"];
                if (a === 0) ++array[0];
                if (a === 1) ++array[1];
                if (a === 2) ++array[2];
                if (b === 3) ++array[3];
                if (c === 4) ++array[4];
                if (b === "ask") --array[0];  //剪掉问路的自定义个数
                if (c === "ask") --array[1];
            }
            let val = {};
            let val1 = {};
            let val2 = {};
            let OthersVal = {
                val3: {},
                val4: {},
                val5: {},
            };
            { //ZhuzaiRoad 算法
                val = {
                    name: a === 0 ? "z" : a === 1 ? "x" : "h",
                    root: a,
                    doublez: b === 3 ? "h" : "",
                    doublex: c === 4 ? "l" : "",
                    url: "",
                    x: parseInt(index / 6),
                    y: index % 6,
                };
                val.url = `${val.name}${val.doublez}${val.doublex}`;
            }
            { //MainRoad 算法 NumRoad 算法
                if (a !== 2 && list["MainRoad"].length === 0) { //初始化第一个位置
                    val1 = {
                        name: a === 0 ? "dqh" : "dql",
                        url: a === 0 ? "dqh" : "dql",
                        turnW: false, //是否转拐
                        continuous: 0, //记录拐弯之后的个数
                        continuousH: 0, //连续和的次数
                        root: a,
                        x: 0,
                        y: 0,
                        trueX: 0,
                        trueY: 0,
                    };
                } else if (list["MainRoad"].length !== 0) {
                    let farmat = list["MainRoad"][list["MainRoad"].length - 1];
                    let farmatNumRoad = list["NumRoad"][list["NumRoad"].length - 1];
                    if (a === 0 || a === 1) val1 = {
                        name: "",
                        url: "",
                        turnW: false, //是否转拐
                        continuous: 0,
                        continuousH: 0,
                        root: a,
                        x: null,
                        y: null,
                        trueX: 0,
                        trueY: 0,
                    };
                    let fn = (state) => {
                        val1.name = val1.url = state === 0 ? "dqh" : "dql";
                        if (farmat.root === state) { //判断上一个是否和当前相等
                            let _x = farmat.x;
                            let _y = farmat.y + 1 > 5 ? 5 : farmat.y + 1;
                            val1.trueX = farmat.trueX;
                            val1.trueY = farmat.trueY + 1;
                            if ((!farmat.turnW && list["MainRoad"].filter(item1 => item1.x === _x && item1.y === _y).length !== 0) || farmat.turnW === true) { //判断是否转弯和上一个是否为拐弯
                                _x = farmat.x + 1;
                                _y = farmat.y;
                                val1.turnW = true;
                                val1.continuous = farmat.continuous + 1;
                            }
                            val1.x = _x;
                            val1.y = _y;
                        }
                        if (farmat.root === (state === 0 ? 1 : 0)) { //判断是否换行
                            let farmatN = list["MainRoad"][list["MainRoad"].length - farmat.continuous - 1];
                            val1.x = farmatN.y === 0 ? farmat.x + 1 : farmatN.x + 1;
                            val1.trueX = farmat.trueX + 1;  //2021.3.26 真实坐标只需是上一次的真实坐标 + 1
                            val1.y = val1.trueY = 0;
                        }
                    };
                    if (a === 0) fn(0); //庄
                    if (a === 1) fn(1); //闲
                    if (a === 2) { //和
                        //这里需要把数字和路径同步到到NumRoad
                        farmat.url = farmat.name === "dqh" ? "dqhx" : "dqlx";
                        farmat.continuousH = farmat.continuousH + 1;
                        //这里是同步操作
                        farmatNumRoad.continuousH = farmat.continuousH;
                        farmatNumRoad.url = farmatNumRoad.name = farmat.url;
                    }
                }
            }
            //小路算法  斜路  曰由路算法  避免使用循环影响性能 采用键值对赋值
            let PathRoadFn = (way, agent, position) => {  //way ==> 表示哪一路  agent ==> 表示当前是哪个val position ==> 表示当前的参照点[[表示首次验证的坐标1x,y,xy路径字符表示],[表示首次验证的坐标2x,y],[红色图片名称,蓝色图片名称],[指定比较的列数1,2,参照列]]
                let {trueX: x, trueY: y, turnW} = val1;
                // x = x || 0;
                //这里需要判断是否是转弯状态
                //如果是转弯状态x要回到转弯前的最后一个
                // console.log("这是前面的", x, turnW, list, val1, way, agent, position, PathRoadYz,);
                if (turnW) {
                    // console.log(list["MainRoad"][list["MainRoad"].length - val1.continuous - 1].x);
                    x = list["MainRoad"][list["MainRoad"].length - val1.continuous - 1].x || 0;  //补丁或者=0 防止坐标取到null
                }
                if (!PathRoadYz[way].hasOwnProperty(x)) PathRoadYz[way][x] = {result: 0}; //默认赋值一次横坐标
                if (index !== this.roadList.length - 1) PathRoadYz[way][x].result++;  //问路时不需要再加1
                PathRoadYz[way][x][`${x}.${y}`] = {};
                OthersVal[agent] = {
                    name: null,
                    root: null, //0表示红 1表示蓝
                    turnW: false, //是否转拐
                    continuous: 0,
                    url: "",
                    x: null,
                    y: null,
                };
                let boolp1 = (x === position[0][0] && y === position[0][1]);  //如果有1  就不看2
                let boolp2 = (x === position[1][0] && y === position[1][1]);
                if (!PathRoadYz[way].isBegin && (boolp1 || boolp2)) {  //验证开始
                    let resultBool = false;
                    PathRoadYz[way].isBegin = true;  //取消未开始标记
                    //看[1,1]是否有露珠 如果有就与参照列相比有为红 无为蓝 或者 [2,0]是否有露珠 如果有判断前面两列的红蓝个数是否相等 相等则为红圈  不相等则为蓝圈
                    if (boolp1) {
                        resultBool = PathRoadYz[way][x - position[3][2]].hasOwnProperty(`${x - position[3][2]}.${y}`);
                    } else if (boolp2) {
                        resultBool = PathRoadYz[way][x - position[3][0]].result === PathRoadYz[way][x - position[3][1]].result;
                    }
                    if (resultBool) {
                        OthersVal[agent] = {
                            ...OthersVal[agent], ...{name: position[2][0], url: position[2][0], root: 0, x: 0, y: 0}
                        };
                    } else {
                        OthersVal[agent] = {
                            ...OthersVal[agent], ...{name: position[2][1], url: position[2][1], root: 1, x: 0, y: 0}
                        };
                    }
                } else if (PathRoadYz[way].isBegin === true) { //已经开始计算露珠之后直接计算位置    遵循有为红 无为蓝 空空为红且支持转弯
                    let bool = false; //默认[x-1,y] [x-1,y-1]位置没有露珠
                    let bool1 = false;
                    //需要判断MainRoad是否处于转弯状态 来判断bool bool1
                    if (val1.turnW) { //MainRoad是处于转弯状态且y坐标不等于0
                        let {trueX: _x, trueY: _y} = list["MainRoad"][list["MainRoad"].length - val1.continuous]; //拿到不是转弯的那个的x坐标
                        bool = PathRoadYz[way][_x - position[3][2]].hasOwnProperty(`${_x - position[3][2]}.${y}`);
                        if (!bool && !PathRoadYz[way][_x - position[3][2]].hasOwnProperty(`${_x - position[3][2]}.${y - 1}`)) bool1 = true;
                    } else if (!val1.turnW && y === 0) { //MainRoad处于新一列的开始 看指定前两列庄闲个数是否相等
                        bool = PathRoadYz[way][x - position[3][0]].result === PathRoadYz[way][x - position[3][1]].result;
                    } else if (!val1.turnW) { //处于不转弯状态且不是新的一列
                        bool = PathRoadYz[way][x - position[3][2]].hasOwnProperty(`${x - position[3][2]}.${y}`);
                        if (!bool && !PathRoadYz[way][x - position[3][2]].hasOwnProperty(`${x - position[3][2]}.${y - 1}`)) bool1 = true;
                    }
                    if (bool || bool1) {  //有为红
                        OthersVal[agent] = {
                            ...OthersVal[agent], ...{name: position[2][0], url: position[2][0], root: 0}
                        };
                    } else if (!bool) { //无为蓝
                        OthersVal[agent] = {
                            ...OthersVal[agent], ...{name: position[2][1], url: position[2][1], root: 1}
                        };
                    }
                    //在这里添加计算x,y坐标
                    let farmat = list[way][list[way].length - 1];
                    //判断上一个是root是否和当前root相等 如果相等则x不变且支持转弯 还需要判断上一个是否已经处于转弯的状态了
                    let fn = (state) => {
                        if (farmat.root === state) { //判断上一个是否和当前相等
                            let _x = farmat.x;
                            let _y = farmat.y + 1 > 5 ? 5 : farmat.y + 1;
                            if ((!farmat.turnW && list[way].filter(item1 => item1.x === _x && item1.y === _y).length !== 0) || farmat.turnW === true) { //判断是否转弯和上一个是否为拐弯
                                _x = farmat.x + 1;
                                _y = farmat.y;
                                OthersVal[agent].turnW = true;
                                OthersVal[agent].continuous = farmat.continuous + 1;
                            }
                            OthersVal[agent].x = _x;
                            OthersVal[agent].y = _y;
                        }
                        if (farmat.root === (state === 0 ? 1 : 0)) { //判断是否换行
                            let farmatN = list[way][list[way].length - farmat.continuous - 1];
                            OthersVal[agent].x = farmatN.y === 0 ? farmat.x + 1 : farmatN.x + 1;
                            OthersVal[agent].y = 0;
                        }
                    };
                    fn(OthersVal[agent]["root"]);
                }
            };
            if (index !== this.roadList.length - 1) {
                list["ZhuzaiRoad"].push(val);
                if (Object.keys(val1).length !== 0) {
                    val2 = {  //NumRoad 其他都相同只是xy需要单独算
                        ...JSON.parse(JSON.stringify(val1)), ...{
                            x: parseInt(list["NumRoad"].length / 3),
                            y: list["NumRoad"].length % 3
                        }
                    };
                    PathRoadFn("PathRoad", "val3", [[1, 1, "1.1"], [2, 0], ["xqh", "xql"], [1, 2, 1]]); //小路只存在大路开始之后
                    if (OthersVal.val3.x !== null && OthersVal.val3.y !== null) list["PathRoad"].push(OthersVal.val3);
                    PathRoadFn("YueyouRoad", "val4", [[2, 1, "2.1"], [3, 0], ["qh", "ql"], [1, 3, 2]]);
                    if (OthersVal.val4.x !== null && OthersVal.val4.y !== null) list["YueyouRoad"].push(OthersVal.val4);
                    PathRoadFn("ObliqueRoad", "val5", [[3, 1, "3.1"], [4, 0], ["hx", "lx"], [1, 4, 3]]);
                    if (OthersVal.val5.x !== null && OthersVal.val5.y !== null) list["ObliqueRoad"].push(OthersVal.val5);
                    list["MainRoad"].push(val1);
                    list["NumRoad"].push(val2);
                }
            } else {
                list["AskRoad"]["ZhuzaiRoad"].push(val);
                if (Object.keys(val1).length !== 0) {
                    val2 = {  //NumRoad 其他都相同只是xy需要单独算
                        ...JSON.parse(JSON.stringify(val1)), ...{
                            x: parseInt(list["NumRoad"].length / 3),
                            y: list["NumRoad"].length % 3
                        }
                    };
                    PathRoadFn("PathRoad", "val3", [[1, 1, "1.1"], [2, 0], ["xqh", "xql"], [1, 2, 1]]); //小路只存在大路开始之后
                    if (OthersVal.val3.x !== null && OthersVal.val3.y !== null) list["AskRoad"]["PathRoad"].push(OthersVal.val3);
                    PathRoadFn("YueyouRoad", "val4", [[2, 1, "2.1"], [3, 0], ["qh", "ql"], [1, 3, 2]]);
                    if (OthersVal.val4.x !== null && OthersVal.val4.y !== null) list["AskRoad"]["YueyouRoad"].push(OthersVal.val4);
                    PathRoadFn("ObliqueRoad", "val5", [[3, 1, "3.1"], [4, 0], ["hx", "lx"], [1, 4, 3]]);
                    if (OthersVal.val5.x !== null && OthersVal.val5.y !== null) list["AskRoad"]["ObliqueRoad"].push(OthersVal.val5);
                    list["AskRoad"]["MainRoad"].push(val1);
                    list["AskRoad"]["NumRoad"].push(val2);
                    if (countask) {
                        this.roadList[this.roadList.length - 1] = [1, "", "ask"]; //这里需要重置小路验证器
                        if (list["PathRoad"].length === 0) PathRoadYz.PathRoad.isBegin = false;
                        if (list["YueyouRoad"].length === 0) PathRoadYz.YueyouRoad.isBegin = false;
                        if (list["ObliqueRoad"].length === 0) PathRoadYz.ObliqueRoad.isBegin = false;
                        index--;
                        countask = false;
                    }
                }
            }
        }
        return list;
    }
}
export default new Calculation();
