<template>
    <div v-if="ShowRegister" class="Register fixed allw allh">
        <div class="setbox absolute position-center flex column relative">
            <div class="margin-auto mat15 mab15 flex items-center content-center">
<!--                <img class="allh" src="../../../public/img/Login/zhuce_t.png">-->
                <img class="allh" :src="RequireImg('Login/zhuce_t.png')">
                <img @click="CloseRegister" class="absolute pointer" src="../../../public/img/Common/gn_gb.png">
            </div>
            <div class="flex1 flex column">
                <div v-for="(item,index) in FromDate" :key="index" :class="[LangeuageAgent === 'CH' ? 'font24' : 'font16']" class="fromDatebox allw border-box flex items-center content-center mab10">
                    <span class="flex1"><font v-if="item.require"
                                              class="colorRed mar5">*</font>{{Lang[item.name]}}：</span>
                    <template v-if="item.name !== 'XMFS'"><!--洗码方式需要判断-->
                        <div class="allh flex inbox">
                            <input v-model="item.result" :placeholder="Lang[item.placeholder]" :type="item.type"
                                   class="allh allw border-box">
                            <img v-if="item.name === 'YZM'" :src="YzmUrl" @click="GetImgUrl" class="mal10 allh backgroundWhite pointer"><!--验证码-->
                        </div>
                    </template>
                    <template v-else>
                        <div class="allh flex">
                            <van-radio-group v-model="item.result" direction="horizontal">
                                <van-radio name="1" class="colorfff">{{Lang['DB']}}</van-radio><!--单边-->
                                <van-radio name="2" class="mal10 colorfff">{{Lang['SB']}}</van-radio><!--双边-->
                            </van-radio-group>
                        </div>
                    </template>
                </div>
                <div @click="RegisterFn"
                     class="btnbox font32 margin-auto flex items-center content-center mab15 pointer">
                    {{Lang['LJZC']}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {GetCaptcha, ApiRegister} from '../../Api';

    export default {
        name: 'Register',
        props: ['DomLogin'],
        computed: {
            ...mapState(['ShowRegister', 'LangeuageAgent'])
        },
        data() {
            return {
                CaptchaId: '',
                YzmUrl: '',
                Code: '',
                FromDate: [
                    {require: true, name: 'ZH', placeholder: '6-12YWSZZH', type: 'text', result: ''},  //账号  请输入6-12个英文和数字账号
                    // {require: false, name: '昵称', placeholder: '请输入昵称', type: 'text'},
                    {require: true, name: 'MM', placeholder: '6-20YWSZMM', type: 'password', result: ''},  //密码  请输入6-20个英文和数字密码
                    {require: true, name: 'QRMM', placeholder: 'QSRMM', type: 'password', result: ''},  //确认密码  请输入密码
                    {require: false, name: 'SJHM', placeholder: 'QSRSJHM', type: 'number', result: ''}, //手机号码  请输入手机号码
                    {require: false, name: 'QKMM', placeholder: 'QSRQKMM', type: 'password', result: ''},   //取款密码  请输入取款密码
                    {require: true, name: 'XMFS', placeholder: 'QXZXMFS', type: 'password', result: '1'},   //洗码方式  请选择洗码方式
                    {require: true, name: 'YZM', placeholder: 'QSRYZM', type: 'text', result: ''}   //验证码  请输入验证码
                ],
            }
        },
        created() {
            this.$nextTick(() => {
                // this.GetImgUrl();
            });
        },
        watch: {
            'ShowRegister': {
                handler(n, o) {
                    if (n) {
                        this.Code = this.$ReConfig.InviteCode;
                        this.CaptchaId = '';
                        this.GetImgUrl();
                    }
                }
            }
        },
        methods: {
            CloseRegister() { //关闭弹窗
                this.SetStore({
                    Prop: 'ShowRegister',
                    Val: false
                })
            },
            GetImgUrl() { //请求验证码
                let data = {};
                if (this.CaptchaId) data = {captchaId: this.CaptchaId};
                GetCaptcha(data).then((res) => {
                    if (res.code !== 0) return;
                    this.CaptchaId = res.data.captchaId;
                    this.YzmUrl = res.data.data;
                })
            },
            RegisterFn() { //注册
                let str = '';
                let regex = {
                    'ZH': /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
                    'MM': /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
                    'YZM': /[0-9]{4}/,
                };
                this.FromDate.forEach((item, index) => {
                    if (item.require && regex[item.name] && !str) {
                        if (!regex[item.name].test(item.result)) str = this.Lang[item.placeholder];
                    }
                });
                if (this.FromDate[1].result !== this.FromDate[2].result && !str) str = this.Lang['MMBYZ'];  //两次密码不一致
                if (str) {
                    this.Toast('2', str);
                    return;
                }
                let FromDate = this.FromDate;
                let data = {
                    username: FromDate[0].result,
                    password: FromDate[1].result,
                    inviteCode: this.Code,  //邀请码
                    xmType: Number(FromDate[5].result), //洗码方式
                    phone: FromDate[3].result,
                    nickname: FromDate[0].result,
                    pinCode: FromDate[4].result,
                    captchaId: this.CaptchaId,
                    captchaValue: FromDate[6].result
                };
                ApiRegister(data).then((res) => {
                    if (res.code !== 0) return;
                    this.Toast('1', this.Lang['ZCCG']);    //注册成功
                    setTimeout(() => {  //自动登录
                        this.CloseRegister();
                        this.DomLogin(FromDate[0].result, FromDate[1].result);
                    }, 3000);
                });
            }
        }
    }
</script>

<style lang="less">
    .Register {
        z-index: 100;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);

        .setbox {
            width: 6rem;
            height: 8rem;
            background-color: rgba(0, 0, 0, .6);
            border: solid 1px #8e7056;

            > div:first-child {
                width: 4.2rem;
                height: .35rem;

                img:last-child {
                    right: .2rem;
                    width: .3rem;
                    height: .3rem;
                }
            }

            > div:last-child {
                border-top: solid 1px #8e7056;
                background-color: #000;

                .fromDatebox {
                    padding: 0 .4rem;
                    height: .6rem;

                    &:first-child {
                        margin-top: .5rem;
                    }

                    span:nth-child(1) {
                        color: #dfd2b7;
                        text-align: right;
                        white-space: nowrap;
                    }

                    > div {
                        width: 3.55rem;

                        &.inbox {
                            input {
                                color: #867e6d;
                                background-color: rgba(255, 255, 255, 1);
                                border-radius: .03rem;
                                padding: 0 .15rem;
                            }

                            img {
                                width: 1.2rem;
                                border-radius: .03rem;
                            }
                        }

                        span.van-radio__label {
                            color: #ffffff;
                        }
                    }
                }

                .btnbox {
                    width: 5rem;
                    height: .7rem;
                    background: url("../../../public/img/Login/dl_zc_btn.png") no-repeat left top /100% 100%;
                    /*border-radius: .03rem;*/
                    margin-top: .85rem;
                    color: #553b14;

                    &:hover {
                        background: url("../../../public/img/Login/dl_zc_btn_hover.png") no-repeat left top /100% 100%;
                        color: #ffffff;
                    }
                }
            }
        }
    }
</style>
