<template>
    <div v-show="ShowVoice" class="SetVoice fixed allw allh">
        <div class="setbox absolute position-center flex column">
            <div class="margin-auto mat15 mab15 flex items-center content-center">
                <img class="allh"
                     :src="'./img/Common/title_xtsz'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">
            </div>
            <div class="flex1 flex column">
                <div class="flex column content-center items-center flex1">
                    <div v-for="(item,index) in VoiceDate" :key="index"
                         class="toolsbox margin-auto flex content-center items-center">
                        <img @click="VoiceAble(index)"
                             :src="'./img/Common/gn_xz'+(item.isable ? 2 : 1)+'.png'"
                             class="pointer">
                        <span class="font30">{{Lang[item.name]}}</span>
                        <div>
                            <van-slider v-model="item.val" :disabled="!item.isable" @change="ShowCurrentNum"
                                        bar-height=".04rem"
                                        button-size=".3rem"
                                        active-color="#bd8865">
                                <template #button>
                                    <div class="sliderimg"></div>
                                </template>
                            </van-slider>
                        </div>
                    </div>
                </div>
                <div class="canclebox font24 flex content-center items-center">
                    <!--          <span class="pointer text-center hover opacity8">确定</span>-->
                    <span @click="CloseDialog" class="pointer text-center hover opacity8">{{Lang['SPGB']}}
                        <!--关闭--></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'SetVoice',
        computed: {
            ...mapState(['ShowVoice', 'LangeuageAgent'])
        },
        data() {
            return {
                value: 0,
                VoiceDate: [
                    {name: 'YXYY', val: 50, isable: true}//游戏音乐
                ]
            }
        },
        created() {
            this.$nextTick(() => {
                this.VoiceDate[0].isable = (localStorage.ablevoice === 'k' || !localStorage.ablevoice) ? true : false;
                if (!this.VoiceDate[0].isable) this.VoiceDate[0].val = 0;
                this.ShowCurrentNum('no');
            });
        },
        methods: {
            VoiceAble(index) {
                this.VoiceDate[index].isable = !this.VoiceDate[index].isable;
                let bool = this.VoiceDate[index].isable;
                localStorage.ablevoice = bool ? 'k' : 'g';
                if (!bool) this.VoiceDate[index].val = 0;
                this.ShowCurrentNum();
            },
            CloseDialog() {
                this.SetStore({
                    Prop: 'ShowVoice',
                    Val: false
                });
            },
            ShowCurrentNum(IsShow) {
                if (IsShow !== 'no') this.Toast('4', `${this.Lang['DQYL']} ${this.VoiceDate[0].val}%`);
                this.SetStore({
                    Prop: 'VoiceNum',
                    Val: this.VoiceDate[0].val / 100
                });
            }
        }
    }
</script>

<style lang="less">
    .sliderimg {
        width: .3rem;
        height: .3rem;
        background-image: linear-gradient(-3deg,
        #8c5538 0%,
        #e9b58a 100%),
        linear-gradient(#373535,
        #373535);
        background-blend-mode: normal,
        normal;
        border-radius: 50%;
    }

    .SetVoice {
        z-index: 100;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);

        .setbox {
            width: 8.6rem;
            height: 4.5rem;
            background-color: rgba(0, 0, 0, .6);
            border: solid 1px #8e7056;

            > div:first-child {
                width: 4.2rem;
                height: .35rem;
                background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;
            }

            > div:last-child {
                border-top: solid 1px #8e7056;
                background-color: #000;

                .toolsbox {
                    > img {
                        width: .47rem;
                        height: .41rem;
                    }

                    > span {
                        color: #af8868;
                        margin: 0 .5rem;
                    }

                    > div {
                        width: 3.5rem;
                    }
                }

                .canclebox {
                    padding: 0 2.1rem 0 2.1rem;
                    margin: .4rem 0;

                    span {
                        height: .6rem;
                        width: 1.55rem;
                        line-height: .6rem;
                    }

                    span:first-child {
                        background: url("../../../public/img/Common/queren_btn_bg.png") no-repeat top center/cover;
                        color: #291c03;
                    }

                    span:last-child {
                        background: url("../../../public/img/Common/quxiao_btn_bg.png") no-repeat top center/cover;
                        color: #c5c5c5;
                    }
                }
            }
        }
    }
</style>
