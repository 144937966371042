import axios from 'axios';
import App from '../main';

let Loading = null;
//增加默认请求路径
// axios.defaults.baseURL = window.Re.Api || 'http://182.61.43.84:9092';  //这里要做更换
axios.defaults.timeout = 10000; //响应时长
axios.interceptors.request.use(res => {
    res.url = (window.Re.Api || 'http://182.61.43.84:9092') + res.url
    // console.log(res.url)
    Loading = App.Toast('3', '', {Time: 99999999});
    if (sessionStorage.Authorization) res.headers.common['Authorization'] = sessionStorage.Authorization;
    return res;
});
axios.interceptors.response.use((res) => {
    Loading.clear();
    if (res.data.code !== 0) App.Dialog(res.data.message, {Time: 3});
    if (res.data) return res.data;
    return res; //在这里统一拦截结果
}, error => {
    Loading.clear();
    // vm.dialog('网络链接超时', 3);
});

function randomUrl(url) {
    return `${url}?${Math.ceil(Math.random() * 1000000)}`
}

export let GameLogin = (params) => { //第一次登录
    return axios.post(randomUrl('/gameLogin'), params);
};

// export let reloginapi = (params) => { //第二次登录
//     return axios.post('/login', params);
// };

export let QuickLogin = (params) => { //快速登陆第一次登录
    return axios.post(randomUrl('/quick/login'), params);
};

// export let relaunchTokenLogin = (params) => { //快速登陆第二次登录
//     return axios.post('/quick/login', params);
// };

export let PlayerDemo = (params) => { //试玩
    return axios.get(randomUrl('/playerDemo'), {params});
};

export let ModifyPass = (params) => { //修改密码
    return axios.post(randomUrl('/modifyPass'), params);
};

export let GetGameRecord = (params) => {  //游戏记录
    return axios.get(randomUrl('/getGameRecord'), {params});
};

export let GetCaptcha = (params) => { //获取验证码
    return axios.get(randomUrl('/getCaptcha'), {params});
};

export let ApiRegister = (params) => { //注册
    return axios.post(randomUrl('/gameApi/register'), params);
};

export let ApiGetConfig = (params) => { //获取配置
    let url = axios.defaults.baseURL.indexOf('gameApi') >= 0 ? randomUrl('/config') : randomUrl('gameApi/config');
    return axios.get(url, {params});
};

export let Pay = (params) => { //充值
    return axios.post(randomUrl('/pay'), params);
};

export let onlineService = (params) => { //在线客服
    return axios.get(randomUrl('/gameApi/user/online_service'), params);
};

export let Instance = axios;  //暴露axios出去
