<template>
    <div class="Login fixed allh allw">
        <!--    <Flash v-show="!showAnimate" :w="259" :h="204"></Flash>-->
        <component :is="CurrentLoginIn" ref="Domlogin" :ShowAnimate="ShowAnimate"></component>
        <AnimateUi v-if="ShowAnimate" @SetProp="SetProp"></AnimateUi>
        <Register :DomLogin="DomLogin"></Register>
        <a v-show="false" id="askflash" href="http://www.adobe.com/go/getflashplayer" rel="nofollow"
           target="_blank"></a>
        <span class="colorfff font20 fixed">Ver:0.7.24.9.39</span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Register from './Register';
    import AnimateUi from './AnimateUi';

    export default {
        name: "Login",
        components: {
            Register,
            AnimateUi,
            Loginbl: () => import('./Loginbl.vue'),
            Loginszwjt: () => import('./Loginszwjt.vue'),
            Loginhmdylc: () => import('./Loginhmdylc.vue'),
            Logindsgj: () => import('./Logindsgj.vue'),
            Loginyhyygj: () => import('./Loginyhyygj.vue'),
            Loginyl: () => import('./Loginyl.vue'),
            Loginwfgj: () => import('./Loginwfgj.vue'),
            Loginxsj: () => import('./Loginxsj.vue'),
        },
        computed: {
            ...mapState(['UserInfo'])
        },
        data() {
            return {
                ShowAnimate: false, //动画是否播放完毕
                IsRegister: false,  //是否显示注册框
                CurrentLoginIn: ''
            }
        },
        created() {
            this.$nextTick(() => {
                // "/?launchtoken=bcfcf5045167ff71979cc497447f2121e2b6e5368c9a6c4d07691115561a7b35b05f6868ff2d7523ab39e842bc4aea48da930553cef6f0c6e44f84115459446889784c415e3fadea027e1799832696e3cc382d6acca09dbfedd6a45e52f2177a&gameType=4&hallType=1&isdemo=0"
                // sessionStorage.removeItem("isDialog");  //移除socket命令限制
                //初始化筹码
                // localStorage.Chip = localStorage.Chip ? localStorage.Chip : JSON.stringify([[5, 10, 20, 50, 100, 500], [5, 10, 20, 50]]);
                sessionStorage.SYSLang = sessionStorage.SYSLang ? sessionStorage.SYSLang : 'CH';  //初始化一种语言
                localStorage.Chip = JSON.stringify([[1, 5, 10, 20, 50, 100], [1, 5, 10, 20], [1, 5, 10, 20], [1, 5, 10, 20], [1, 5, 10, 20]]);
                // localStorage.Chip = JSON.stringify([[1, 5, 10, 20, 50, 100], [1], [5], [10], [20]]);
                //获取配置文件信息
                if (this.$ReConfig.LoginTemplate === 'wzzz' || this.$ReConfig.LoginTemplate === 'hjgj') {
                    this.CurrentLoginIn = 'Loginyhyygj';
                }else {
                    this.CurrentLoginIn = 'Login' + this.$ReConfig.LoginTemplate;
                }
                if (['wzzz', 'hmdylc', 'dsgj', 'yhyygj', 'hjgj', 'xsj'].includes(this.$ReConfig.LoginTemplate)) this.ShowAnimate = false;
                this.SetStore({
                    Prop: 'DefaultConfig',
                    Val: this.$ReConfig
                });
            });
        },
        methods: {
            SetProp(key, val) {
                if (key === 'ShowAnimate') {
                    setTimeout(() => {
                        this[key] = val;
                    }, 500);
                    return false;
                }
                this[key] = val;
            },
            js_method() {
                return false;
            },
            DomLogin(DefaultName, DefaultsPassword) {  //操作登录组件
                this.$refs['Domlogin'].ForRegister(DefaultName, DefaultsPassword);
            },
        }
    }
</script>

<style lang="less">
    .Login {
        top: 0;
        left: 0;
        min-width: 6.95rem;
        z-index: 10;

        span:last-child {
            left: .2rem;
            bottom: .3rem;
        }
    }
</style>
