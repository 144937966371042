<template>
    <div class="AnimateUi absolute">
        <div :class="{'BeginClass':BeginClass,'ThirdClass':ThirdClass}" class="allw allh">
            <img v-for="(item,index) in Brand" :src="'./img/Login/' + item + '.png'" :key="index"
                 :class="{'SecondClass':SecondClass,'unSecondClass':!SecondClass && ThirdClass,'FourthClass':FourthClass,'FifthClass':FifthClass}"
                 class="absolute">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AnimateUi',
        data() {
            return {
                Brand: ['xz_fangkuang', 'xz_meihua', 'xz_hongtao', 'xz_heitao'],
                BeginClass: true, //开场动画
                SecondClass: false, //卡牌旋转动画
                ThirdClass: false,  //外部盒子旋转
                FourthClass: false,//卡牌发牌动画 -> 直到消失
                FifthClass: false, //旋转消失
            }
        },
        created() {
            this.$nextTick(() => {
                this.AnimateLoading();
            });
        },
        methods: {
            AnimateLoading() {
                let time = 1000;
                setTimeout(() => {
                    this.BeginClass = false;
                    this.SecondClass = true;
                    setTimeout(() => {
                        this.SecondClass = false;
                        this.ThirdClass = true;
                        setTimeout(() => {
                            this.FourthClass = true;
                            setTimeout(() => {
                                this.FifthClass = true;
                                this.$emit('SetProp', 'ShowAnimate', false);
                            }, 0.5 * time);
                        }, time);
                    }, time);
                }, 1.5 * time)
            }
        }
    }
</script>

<style lang="less">
    .BeginClass {
        animation: animate1 1s infinite;
        animation-direction: alternate;
    }

    @keyframes animate1 {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }

    .SecondClass {
        animation-duration: .8s;
        animation-fill-mode: forwards;

        &:first-child {
            animation-name: animate21;
        }

        &:nth-child(2) {
            animation-name: animate22;
        }

        &:nth-child(3) {
            animation-name: animate23;
        }

        &:last-child {
            animation-name: animate24;
        }
    }

    @keyframes animate21 {
        0% {
            left: 50%;
            top: 80%
        }
        100% {
            top: 50%;
            left: 35%;
            transform: rotate(-90deg);
        }
    }

    @keyframes animate22 {
        0% {
            left: 50%;
            top: 80%
        }
        100% {
            top: 50%;
            left: 65%;
            transform: rotate(90deg);
        }
    }

    @keyframes animate23 {
        0% {
            left: 50%;
            top: 80%
        }
        100% {
            top: 35%;
            left: 50%;
        }
    }

    @keyframes animate24 {
        0% {
            left: 50%;
            top: 80%
        }
        100% {
            top: 65%;
            left: 50%;
        }
    }

    .unSecondClass {
        &:first-child {
            top: 50%;
            left: 35%;
            transform: rotate(-90deg);
        }

        &:nth-child(2) {
            top: 50%;
            left: 65%;
            transform: rotate(90deg);
        }

        &:nth-child(3) {
            top: 35%;
            left: 50%;
        }

        &:last-child {
            top: 65%;
            left: 50%;
        }
    }

    .ThirdClass {
        animation: animate3 1s;
        transform-origin: center center;
    }

    @keyframes animate3 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .FourthClass {
        transition: .5s;
        top: 80% !important;
        transform: rotate(0deg);

        &:first-child {
            left: 20%;
            transform: rotate(0deg);
        }

        &:nth-child(2) {
            left: 40%;
            transform: rotate(0deg);
        }

        &:nth-child(3) {
            left: 60%;
        }

        &:last-child {
            left: 80%;
        }
    }

    .FifthClass {
        transition: .5s;
        transform: rotateY(90deg) !important;
    }

    .AnimateUi {
        width: 700px;
        height: 700px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
            width: 60px;
            height: 80px;
            top: 80%;
            left: 50%;
            margin-left: -30px;
            margin-top: -40px;
        }
    }
</style>
