<template>
    <div v-show="ShowGameRecharge" class="GameRecharge fixed allw allh">
        <div class="setbox absolute position-center flex column relative">
            <div class="margin-auto mat15 mab15 flex items-center content-center">
                <img class="allh"
                     :src="'./img/Common/cunkuan_title'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">
                <img @click="CloseDialog" class="absolute pointer" src="../../../public/img/Common/gn_gb.png" alt="">
            </div>
            <div class="flex1 DetailContent flex warp content-between">
                <div class="margin-auto font16">
                    <p class="color999 mab20">{{Lang['QXZCZFS']}}<!--请选择充值方式--></p>
                    <div class="PayWay flex content-between mab25">
                        <div @click="PayCode = '2'" class="flex1 relative pointer mar10">
                            <img v-if="PayCode === '2'" class="absolute" src="../../../public/img/Common/gou.png"
                                 alt="图片显示错误">
                        </div>
                        <div @click="PayCode = '1'" class="flex1 relative pointer">
                            <img v-if="PayCode === '1'" class="absolute" src="../../../public/img/Common/gou.png"
                                 alt="图片显示错误">
                        </div>
                    </div>
                    <p class="color999 mab15">{{Lang['QXZCZFS']}}<!--请选择充值方式--></p>
                    <div class="InPond allw flex warp content-between">
                        <p v-for="(item,index) in InPond" :key="index" @click="Amount = item"
                           :class="{'PondCurrent':Amount === item}"
                           class="font18 flex items-center content-center pointer">
                            {{item}}</p>
                    </div>
                    <p class="color999 mab15">{{Lang['CKJE']}}<!--存款金额--></p>
                    <!--                    请输入存款金额100~1000000-->
                    <input v-model="InAmount" class="allw border-box mab20" :placeholder="Lang['QSRCKJE']"
                           type="number">
                    <!--                    请输入存款人-->
                    <input v-model="TransName" class="allw border-box" :placeholder="Lang['QSRCKR']" type="text">
                    <div @click="PayFn" class="colorfff text-center pointer hover opacity8">{{Lang['TJSQ']}}
                        <!--提交--></div>
                    <!--                    <p class="text-center">充值完成后，请到资金中将余额转换到游戏账户</p>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Pay} from '../../Api';
    import {mapState} from 'vuex';

    export default {
        name: 'GameRecords',
        computed: {...mapState(['ShowGameRecharge', 'LangeuageAgent'])},
        data() {
            return {
                PayCode: '2',
                Amount: '100',
                InAmount: "",
                TransName: "",
                TitleName: {
                    Current: '存款',
                    TitleArr: ['存款']
                },
                InPond: ['100', '200', '300', '400', '500', '1000']
            }
        },
        methods: {
            PayFn() {    //存款
                let Reg = /((^[1-9]\d*)|^0)(\.\d{1,2}){0,1}$/;
                let Data = {
                    'payCode': this.PayCode,
                    'amount': this.Amount,
                    'transName': this.TransName
                };
                if (this.InAmount) {
                    if (!Reg.test(this.InAmount)) {
                        this.Toast('2', this.Lang['QSRCKJE'], {Time: 3});//请输入正确的金额
                        return;
                    }
                    Data['amount'] = this.InAmount;
                }
                if (!this.TransName) {
                    this.Toast('2', this.Lang['QSRCKR'], {Time: 3});//请输入存款人
                    return;
                }
                Pay(Data).then((resp) => {
                    if (resp.code !== 0) return;
                    window.open(resp.data.result);
                });
            },
            CloseDialog() {
                this.SetStore({
                    Prop: 'ShowGameRecharge', Val: false
                })
            },
        }
    }
</script>

<style lang="less">
    .GameRecharge {
        z-index: 100;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);

        .setbox {
            width: 8rem;
            height: 7rem;
            background-color: rgba(0, 0, 0, .6);
            border: solid 1px #8e7056;

            > div:first-child {
                width: 4.2rem;
                height: .35rem;
                background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;

                img:last-child {
                    right: .2rem;
                    width: .3rem;
                    height: .3rem;
                }
            }

            > div:last-child {
                background-color: #201f1f;

                > div {
                    width: 4.35rem;

                    > p:first-child {
                        margin-top: .2rem;
                    }

                    .PayWay {
                        div {
                            height: .7rem;
                            background: url("../../../public/img/Common/wy_zf.png") no-repeat top left/contain;

                            &:first-child {
                                background: url("../../../public/img/Common/zfb_zf.png") no-repeat top left/contain;
                            }

                            img {
                                right: .025rem;
                                bottom: -.01rem;
                            }
                        }
                    }

                    .InPond {
                        p {
                            width: 1.34rem;
                            height: .48rem;
                            color: #89645c;
                            border: solid 1px #41302f;
                            border-radius: 6px;
                            margin-bottom: .12rem;

                            &.PondCurrent {
                                color: #ffffff;
                                background: url("../../../public/img/Common/chaxun_btn.png") no-repeat top left/cover;
                            }

                            &:hover {
                                color: #ffffff;
                                background: url("../../../public/img/Common/chaxun_btn.png") no-repeat top left/cover;
                            }
                        }
                    }

                    > p:nth-child(5) {
                        margin-top: .13rem;
                    }

                    > input {
                        height: .58rem;
                        line-height: .58rem;
                        border: solid 1px #41302f;
                        display: block;
                        padding: 0 .2rem;
                        color: #89645c;
                    }

                    > input:nth-child(7) {
                        margin-bottom: .4rem;

                    }

                    > div:nth-child(8) {
                        height: .6rem;
                        line-height: .6rem;
                        background: url("../../../public/img/Common/chaxun_btn.png") no-repeat left center/cover;
                        border-radius: 4px;
                    }

                    > p:last-child {
                        color: #fd6767;
                        margin-top: .9rem;
                        margin-bottom: .4rem;
                    }
                }
            }
        }
    }
</style>
