<template>
    <div v-show="ShowGameRecords" class="GameRecords fixed allw allh">
        <div class="setbox absolute position-center flex column relative">
            <div class="margin-auto mat15 mab15 flex items-center content-center">
                <img class="allh"
                     :src="'./img/Common/title_yxjl'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">
                <img @click="CloseDialog" class="absolute pointer" src="../../../public/img/Common/gn_gb.png" alt="">
            </div>
            <div class="flex1 flex column">
                <div class="conditionbox flex items-center">
                    <div :class="[LangeuageAgent === 'CH' ? 'font30' : 'font20']"
                         class="flex items-center content-center">
                        {{Lang['YXDT']}}<!--游戏大厅-->
                        <p class="flex font24 color333 items-center content-between border-box pointer opacity8">
                            {{Lang['QB']}}<!--全部-->
                            <img src="../../../public/img/Common/yxjl_sjx.png" alt="">
                        </p>
                    </div>
                    <div :class="[LangeuageAgent === 'CH' ? 'font30' : 'font20']"
                         @mouseleave="ShowConditionGameTypeSelect = false"
                         class="flex items-center content-center relative">
                        {{Lang['YXLX']}}<!--游戏类型-->
                        <p @click="ShowConditionGameTypeSelect = true"
                           class="flex font24 color333 items-center content-between border-box pointer opacity8">
                            {{Lang[CurrentGameType]}}
                            <img src="../../../public/img/Common/yxjl_sjx.png" alt="">
                        </p>
                        <div v-show="ShowConditionGameTypeSelect"
                             class="conditionselect absolute flex column backgroundWhite">
              <span v-for="(item,index) in ConditionGameTypeSelect" :key="index" @click="ChoseGameType(index)"
                    class="font24 colorfff pointer border-box">{{Lang[item]}}</span>
                        </div>
                    </div>
                    <div :class="[LangeuageAgent === 'CH' ? 'font24' : 'font16']" class="ConditionData flex">
                        <p v-for="(item,index) in ConditionData" :key="index" @click="ChoseDataIndex(index)"
                           :class="{'currentConditionData':ConditionDataIndex === index}"
                           class="flex items-center content-center pointer opacity8">{{Lang[item]}}</p>
                    </div>
                </div>
                <div class="tablebox flex1 relative">
                    <table class="allw font16 text-center" border="1" cellspacing="0">
                        <thead>
                        <tr>
                            <th v-for="(item,index) in Thead" :key="index">
                                <img :src="'./img/Common/'+ item + (LangeuageAgent === 'CH' ? '' : LangeuageAgent) +'.png'"
                                     class="margin-auto">
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in BodyData" :key="index + item.winTotal">
                            <!--                            <td>{{ShowGameName(item)}}</td>-->
                            <td>{{item.gameType}}</td>
                            <td>{{item.gameName}}</td>
                            <td>{{item.chang}} - {{item.ci}}</td>
                            <td>{{item.createTime}}</td>
                            <td @click="ShowBetDetail(item)" class="pointer hover">{{Lang['DJCK']}}<!--点击查看--></td>
                            <!--                            <td>{{ComputedResult(item)}}</td>-->
                            <td>{{item.gameResult}}</td>
                            <td>{{item.winTotal}}</td>
                            <td>{{item.balance}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="Pagebox flex items-center content-center font30 relative colorfff">
                    <span class="absolute">{{Lang['YMZJ']}}<!--总计-->：<font>{{Page.count}}</font></span>
                    <div class="flex items-center allh">
                        <img @click="GetRecords('down')" class="mar15 pointer opacity8"
                             src="../../../public/img/Common/shangyiye.png" alt="">
                        <span>{{Page.Pageindex}}</span>
                        <span class="mal5 mar5">/</span>
                        <span>{{Page.total}}</span>
                        <img @click="GetRecords('up')" class="mal15 pointer opacity8"
                             src="../../../public/img/Common/xiayiye.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div v-show="BetDetail" class="BetDetailbox absolute position-center flex column items-center warp font30">
            <img @click="BetDetail = false" class="absolute pointer opacity8"
                 src="../../../public/img/Common/gn_gb.png">
            <span class="mat10 mab25">{{Lang['XZXQ']}}<!--下注详情--></span>
            <span>{{BetDetailData}}</span>
        </div>
    </div>
</template>

<script>
    import {GetGameRecord} from '../../Api';
    import {mapState} from 'vuex';

    export default {
        name: 'GameRecords',
        computed: {...mapState(['ShowGameRecords', 'LangeuageAgent'])},
        data() {
            return {
                ConditionData: ['JRBB', 'ZRBB', 'QRBB'],//'今日报表', '昨日报表', '七日报表'
                ConditionDataIndex: 0,
                CurrentGameType: 'QB',//'全部',
                ConditionGameTypeSelect: ['QB', 'BJL', 'LH', 'NN', 'DS', 'DXD', 'SG', 'TTZ', 'ZXN'],//'全部', '百家乐', '龙虎', '牛牛', '单双', '大小点', '三公', '推筒子'
                ShowConditionGameTypeSelect: false,
                Thead: ['yxjl_t_yxmc', 'yxjl_t_th', 'yxjl_t_xh', 'yxjl_t_jssj', 'yxjl_t_xzje', 'yxjl_t_kjjg', 'yxjl_t_sy', 'yxjl_t_ye'],
                Page: { //分页
                    Pageindex: 1,
                    Pagesize: 10,
                    total: 0,
                    count: 0,
                },
                BodyData: [], //数据
                BetDetail: false,
                BetDetailData: '',
            }
        },
        watch: {
            'ShowGameRecords': {
                handler(n, o) {
                    if (n) {
                        this.ConditionDataIndex = 0;
                        this.ChoseGameType(0);
                    }
                }
            }
        },
        methods: {
            ChoseGameType(index) {
                this.CurrentGameType = this.ConditionGameTypeSelect[index];
                this.ShowConditionGameTypeSelect = false;
                this.Page.Pageindex = 1;
                this.GetRecords();
            },
            ChoseDataIndex(index) {
                this.ConditionDataIndex = index;
                this.Page.Pageindex = 1;
                this.GetRecords();
            },
            CloseDialog() {
                this.SetStore({
                    Prop: 'ShowGameRecords', Val: false
                })
            },
            GetRecords(str) {
                if (str === 'down') {
                    if (this.Page.Pageindex <= 1) return;
                    this.Page.Pageindex--;
                } else if (str === 'up') {
                    if (this.Page.Pageindex >= this.Page.total) return;
                    this.Page.Pageindex++;
                }
                let data = new Date();
                let today = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate();
                let time = new Date(today).getTime();
                let difference = 0;
                if (this.ConditionDataIndex === 1) {
                    difference = 1
                }
                if (this.ConditionDataIndex === 2) {
                    difference = 6
                }
                let startTime = time - difference * 24 * 3600 * 1000;
                startTime = new Date(startTime).getFullYear() + '-' + (new Date(startTime).getMonth() + 1) + '-' + new Date(startTime).getDate() + ' 00:00:00';
                GetGameRecord({
                    startTime,
                    endTime: today + ' 23:59:59',
                    pageIndex: this.Page.Pageindex,
                    pageSize: this.Page.Pagesize,
                    gameType: this.ConditionGameTypeSelect.indexOf(this.CurrentGameType)
                }).then((res) => {
                    this.BodyData = res.data.data;
                    this.Page.count = res.data.count;
                    this.Page.total = Math.ceil(res.data.count / 10);
                });
            },
            ShowGameName(item) {
                let name = {
                    '1': 'BJL',//百家乐
                    '2': 'LH',//龙虎
                    '3': 'NN',//牛牛
                    '4': 'DS',//单双
                    '5': 'DXD',//大小点
                    '6': 'SG',//三公
                    '7': 'TTZ'//推筒子
                };
                return this.Lang[name[item.gameType]];
            },
            ComputedResult(item) {
                // 1百家乐   2龙虎    3牛牛  4单双 5大小点 6三公 7推筒子
                let str = '';
                let gameResult = JSON.parse(item.gameResult);
                if (item.gameType === 1) {
                    let [a, b, c] = gameResult;
                    let Trans = {
                        'CH': {
                            'ZY': '庄赢,',
                            'XY': '闲赢,',
                            'HJ': '和局,',
                            'ZD': '庄对,',
                            'XD': '闲对,'
                        },
                        'VNĐ': {
                            'ZY': 'Nhà cái,',
                            'XY': 'Người chơi,',
                            'HJ': 'Ván hòa,',
                            'ZD': 'B Cặp đôi,',
                            'XD': 'P Cặp đôi,'
                        }
                    };
                    let _trans = Trans[this.LangeuageAgent];
                    if (a === 0) str += _trans['ZY'];
                    if (a === 1) str += _trans['XY'];
                    if (a === 2) str += _trans['HJ'];
                    if (b === 3) str += _trans['ZD'];
                    if (c === 4) str += _trans['XD'];
                } else if (item.gameType === 2) {
                    let [a] = gameResult;
                    let Trans = {
                        'CH': {
                            'LY': '龙赢,',
                            'HY': '虎赢,',
                            'HJ': '和局,'
                        },
                        'VNĐ': {
                            'LY': 'Rồng,',
                            'HY': 'Hổ,',
                            'HJ': 'Ván hòa,'
                        }
                    };
                    let _trans = Trans[this.LangeuageAgent];
                    if (a === 0) str += _trans['LY'];
                    if (a === 1) str += _trans['HY'];
                    if (a === 2) str += _trans['HJ'];
                } else if (item.gameType === 3) {
                    let [a, b, c, d] = gameResult;
                    let Trans = {
                        'CH': {
                            'Z': '庄,',
                            'X1': '闲一,',
                            'X2': '闲二,',
                            'X3': '闲三,'
                        },
                        'VNĐ': {
                            'Z': 'Nhà cái,',
                            'X1': 'Người chơi 1,',
                            'X2': 'Người chơi 2,',
                            'X3': 'Người chơi 3,'
                        }
                    };
                    let _trans = Trans[this.LangeuageAgent];
                    let fn = () => {
                        str += _trans['Z'] + (a - 1) + ',';
                    };
                    if (b > 0) {
                        str += _trans['X1'] + (b - 1) + ',';
                    } else {
                        fn();
                    }
                    if (c > 0) {
                        str += _trans['X2'] + (c - 1) + ',';
                    } else {
                        fn();
                    }
                    if (d > 0) {
                        str += _trans['X3'] + (d - 1) + ',';
                    } else {
                        fn();
                    }
                } else if (item.gameType === 4) {
                    let [a] = gameResult;
                    let Trans = {
                        'CH': {
                            'DSD': '单,',
                            'DSS': '双,'
                        },
                        'VNĐ': {
                            'DSD': 'Lẻ,',
                            'DSS': 'Chẵn,'
                        }
                    };
                    let _trans = Trans[this.LangeuageAgent];
                    if (a === 0) str += _trans['DSD'];
                    if (a === 1) str += _trans['DSS'];
                } else if (item.gameType === 5) {
                    let [a] = gameResult;
                    let Trans = {
                        'CH': {
                            'DXTD': '大,',
                            'DXTX': '小,',
                            'DXTT': '同,'
                        },
                        'VNĐ': {
                            'DXTD': 'Lớn,',
                            'DXTX': 'Nhỏ,',
                            'DXTT': 'Điểm giống nhau,'
                        }
                    };
                    let _trans = Trans[this.LangeuageAgent];
                    if (a === 0) str += _trans['DXTD'];
                    if (a === 1) str += _trans['DXTX'];
                    if (a === 2) str += _trans['DXTT'];
                } else if (item.gameType === 6) {
                    let [a, b, c, d] = gameResult;
                    let Trans = {
                        'CH': {
                            'SG': '三公,',
                            'D': '点',
                            'ZJ': '庄',
                            'XJ1': '闲一',
                            'XJ2': '闲二',
                            'XJ3': '闲三'
                        },
                        'VNĐ': {
                            'SG': ' Sâm Lốc,',
                            'D': ' điểm',
                            'ZJ': 'Nhà cái',
                            'XJ1': 'Người chơi 1',
                            'XJ2': 'Người chơi 2',
                            'XJ3': 'Người chơi 3'
                        }
                    };
                    let _trans = Trans[this.LangeuageAgent];
                    let fn = (num) => {
                        let result = Math.abs(num) - 1;
                        if (result === 10) return _trans['SG'];
                        return result + _trans['D'];
                    };
                    let fnz = () => {
                        str += _trans['ZJ'] + fn(a) + ',';
                    };
                    if (b > 0) {
                        str += _trans['XJ1'] + fn(b) + ',';
                    } else {
                        fnz();
                    }
                    if (c > 0) {
                        str += _trans['XJ2'] + fn(c) + ',';
                    } else {
                        fnz();
                    }
                    if (d > 0) {
                        str += _trans['XJ3'] + fn(d) + ',';
                    } else {
                        fnz();
                    }
                } else if (item.gameType === 7) {
                    let [a, b, c, d, e] = gameResult;
                    let Trans = {
                        'CH': {
                            'ZJ': '庄',
                            'T1': '筒一',
                            'T2': '筒二',
                            'T3': '筒三',
                            'T4': '筒四',
                            'TD': '天对',
                            'D1': '对一',
                            'D2': '对二',
                            'D3': '对三',
                            'D4': '对四',
                            'D5': '对五',
                            'D6': '对六',
                            'D7': '对七',
                            'D8': '对八',
                            'D9': '对九',
                        },
                        'VNĐ': {
                            'ZJ': 'Nhà cái ',
                            'T1': 'Văn 1',
                            'T2': 'Văn 2',
                            'T3': 'Văn 3',
                            'T4': 'Văn 4',
                            'TD': 'Chúa nói đúng',
                            'D1': 'Cặp đôi 1',
                            'D2': 'Cặp đôi 2',
                            'D3': 'Cặp đôi 3',
                            'D4': 'Cặp đôi 4',
                            'D5': 'Cặp đôi 5',
                            'D6': 'Cặp đôi 6',
                            'D7': 'Cặp đôi 7',
                            'D8': 'Cặp đôi 8',
                            'D9': 'Cặp đôi 9',
                        }
                    };
                    let _trans = Trans[this.LangeuageAgent];
                    let fn = (num) => {
                        let _result = {
                            '1': '0',
                            '2': '1',
                            '3': '2',
                            '4': '3',
                            '5': '4',
                            '6': '5',
                            '7': '6',
                            '8': '7',
                            '9': '8',
                            '10': '9',
                            '11': _trans['D1'],
                            '12': _trans['D2'],
                            '13': _trans['D3'],
                            '14': _trans['D4'],
                            '15': _trans['D5'],
                            '16': _trans['D6'],
                            '17': _trans['D7'],
                            '18': _trans['D8'],
                            '19': _trans['D9'],
                            '20': _trans['TD']
                        };
                        return _result[Number(num)];
                    };
                    let fnz = () => {
                        str += _trans['ZJ'] + fn(a) + ',';
                    };
                    if (b > 0) {
                        str += _trans['T1'] + fn(b) + ',';
                    } else {
                        fnz();
                    }
                    if (c > 0) {
                        str += _trans['T2'] + fn(c) + ',';
                    } else {
                        fnz();
                    }
                    if (d > 0) {
                        str += _trans['T3'] + fn(d) + ',';
                    } else {
                        fnz();
                    }
                    if (e > 0) {
                        str += _trans['T4'] + fn(e) + ',';
                    } else {
                        fnz();
                    }
                }
                str = str.substr(0, str.length - 1);
                return str;
            },
            ShowBetDetail(item) {
                // 1百家乐 2龙虎 3牛牛
                // let str = '';
                // let betTxt = JSON.parse(item.betTxt);
                // let name = [];
                // let _lang = this.Lang;
                // if (item.gameType === 1) {
                //     name = [_lang['ZJ'] + ':', _lang['XJ'] + ':', _lang['HJ'] + ':', _lang['ZD'] + ':', _lang['XD'] + ':'];//'庄:', '闲:', '和:', '庄对:', '闲对:'
                // } else if (item.gameType === 2) {
                //     name = [_lang['LHL'] + ':', _lang['LHH'] + ':', _lang['HJ'] + ':'];//'龙:', '虎:', '和:'
                // } else if (item.gameType === 3) {
                //     name = [
                //         `${_lang['XJ']}1${_lang['PB']}:`,
                //         `${_lang['XJ']}1${_lang['FB']}:`,
                //         `${_lang['XJ']}1${_lang['FBDB']}:`,
                //         `${_lang['XJ']}2${_lang['PB']}:`,
                //         `${_lang['XJ']}2${_lang['FB']}:`,
                //         `${_lang['XJ']}2${_lang['FBDB']}:`,
                //         `${_lang['XJ']}3${_lang['PB']}:`,
                //         `${_lang['XJ']}3${_lang['FB']}:`,
                //         `${_lang['XJ']}3${_lang['FBDB']}:`
                //     ];//'闲1平倍:', '闲1翻倍:', '闲1多倍:', '闲2平倍:', '闲2翻倍:', '闲2多倍:', '闲3平倍:', '闲3翻倍:', '闲3多倍:'
                // } else if (item.gameType === 4) {
                //     name = [_lang['DSD'], _lang['DSS']];//'单', '双'
                // } else if (item.gameType === 5) {
                //     name = [_lang['DXTD'], _lang['DXTX'], _lang['DXTT']];//'大', '小', '同'
                // } else if (item.gameType === 6) {
                //     name = [`${_lang['XJ']}1${_lang['PB']}:`, `${_lang['XJ']}1${_lang['FB']}:`, `${_lang['XJ']}1${_lang['FBDB']}:`, `${_lang['XJ']}2${_lang['PB']}:`, `${_lang['XJ']}2${_lang['FB']}:`, `${_lang['XJ']}2${_lang['FBDB']}:`, `${_lang['XJ']}3${_lang['PB']}:`, `${_lang['XJ']}3${_lang['FB']}:`, `${_lang['XJ']}3${_lang['FBDB']}:`];//'闲1平倍:', '闲1翻倍:', '闲1多倍:', '闲2平倍:', '闲2翻倍:', '闲2多倍:', '闲3平倍:', '闲3翻倍:', '闲3多倍:'
                // } else if (item.gameType === 7) {
                //     name = [
                //         `${_lang['TTZT']}1${_lang['PB']}:`,
                //         `${_lang['TTZT']}1${_lang['FB']}:`,
                //         `${_lang['TTZT']}1${_lang['FBDB']}:`,
                //         `${_lang['TTZT']}2${_lang['PB']}:`,
                //         `${_lang['TTZT']}2${_lang['FB']}:`,
                //         `${_lang['TTZT']}2${_lang['FBDB']}:`,
                //         `${_lang['TTZT']}3${_lang['PB']}:`,
                //         `${_lang['TTZT']}3${_lang['FB']}:`,
                //         `${_lang['TTZT']}3${_lang['FBDB']}:`,
                //         `${_lang['TTZT']}4${_lang['PB']}:`,
                //         `${_lang['TTZT']}4${_lang['FB']}:`,
                //         `${_lang['TTZT']}4${_lang['FBDB']}:`];//'筒1平倍:', '筒1翻倍:', '筒1多倍:', '筒2平倍:', '筒2翻倍:', '筒2多倍:', '筒3平倍:', '筒3翻倍:', '筒3多倍:', '筒4平倍:', '筒4翻倍:', '筒4多倍:'
                // }
                // name.forEach((item1, index) => {
                //     if (betTxt[index] !== 0 && item.gameType !== 3) str += (item1 + betTxt[index] + '，');
                //     if (item.gameType === 3) {
                //         str += (item1 + betTxt[index] + ((index === 2 || index === 5) ? '\n' : '，'));
                //     }
                // });
                // if (str.length !== 0) str = str.substr(0, str.length - 1);
                // this.BetDetailData = str;
                this.BetDetailData = item.betTxt;
                this.BetDetail = true;
            }
        }
    }
</script>

<style lang="less">
    .GameRecords {
        z-index: 100;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .6);

        .setbox {
            width: 15rem;
            height: 6.7rem;
            background-color: rgba(0, 0, 0, .6);
            border: solid 1px #8e7056;

            > div:first-child {
                width: 4.2rem;
                height: .35rem;
                background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;

                img:last-child {
                    right: .2rem;
                    width: .3rem;
                    height: .3rem;
                }
            }

            > div:last-child {
                border-top: solid 1px #8e7056;
                background-color: #000;
                padding: 0 .3rem 0 .3rem;

                .conditionbox {
                    height: .8rem;

                    > div:not(:last-child) {
                        margin-right: .6rem;
                    }

                    > div:not(.ConditionData) {
                        color: #f4f4f5;

                        p {
                            width: 2.8rem;
                            height: .5rem;
                            background: url("../../../public/img/Common/yxjl_xk.png") no-repeat top left/contain;
                            margin-left: .15rem;
                            padding: 0 .2rem;
                        }

                        .conditionselect {
                            width: 2.8rem;
                            right: 0;
                            top: .5rem;
                            border-radius: .1rem;
                            background-color: #333333;
                            z-index: 10;

                            span {
                                padding: .1rem .2rem;
                                border-radius: .1rem;

                                &:hover {
                                    opacity: .8;
                                    background-color: #8e8d8a;
                                }
                            }
                        }
                    }

                    .ConditionData {
                        color: #c5c5c5;

                        p {
                            width: 1.55rem;
                            height: .4rem;
                            border-radius: .05rem;
                            background-color: #3a3a3a;

                            &.currentConditionData {
                                background: url("../../../public/img/Common/yxjl_btn_hover.png") no-repeat top left/contain;
                                color: #291c03;
                            }

                            &:nth-child(2n) {
                                margin: 0 .1rem;
                            }
                        }
                    }
                }
            }
        }

        .tablebox {
            background-color: #3f3d3b;

            &:after {
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                border-left: 1px solid #ffffff;
                border-right: 1px solid #ffffff;
                border-bottom: 1px solid #ffffff;
                pointer-events: none;
            }

            table {
                border-color: #ffffff;

                thead {
                    th {
                        height: .6rem !important;
                        background: url("../../../public/img/Common/yxjl_biaotou_bg.jpg") repeat-x left top/contain;
                    }
                }

                tbody {

                    tr {
                        height: .37rem;

                        td {
                            color: #f4f4f5;
                        }
                    }
                }
            }
        }

        .Pagebox {
            height: .9rem;

            > span {
                left: 0;
                top: 50%;
                transform: translate(0, -50%);

                font {
                    color: #d2e86f;
                }
            }

            > div {
                img {
                    width: .4rem;
                }
            }
        }

        .BetDetailbox {
            min-width: 6rem;
            /*height: 1.4rem;*/
            padding-bottom: .2rem;
            padding-left: .1rem;
            padding-right: .1rem;
            background-color: #4f4a4a;
            border: solid 2px #a38063;
            color: #f4f4f5;

            img {
                width: .3rem;
                top: .1rem;
                right: .1rem;
            }

            span {
                white-space: pre-line;
                text-align: justify;
            }
        }
    }
</style>
