import Vue from 'vue'
import VueRouter from 'vue-router'
// import GameHall from '../views/GameHall'
import Login from '../views/Login';

Vue.use(VueRouter)

function getAbsolutePath() {
    let path = location.pathname;
    return path.substring(0, path.lastIndexOf('/') + 1);
};

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/GameHall',
        name: 'GameHall',
        component: () => import('../views/GameHall/index.vue')
    }
    ,
    {
        path: '/GameIn',
        name: 'GameIn',
        component: () => import('../views/GameIn/index.vue')
    },
    {
        path: '/Multiple',
        name: 'Multiple',
        component: () => import('../views/Multiple/index.vue'),
        children: []
    },
    {
        path: '*',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
    mode: 'history',
    base: getAbsolutePath(),
    routes
})

export default router
