import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MinXin from './MinXin'
// import Vant from 'vant';
import 'vant/lib/index.css';
import * as SocketApi from './Api/websocket';
import Calculation from './Api/roadList';
import {Toast, Slider, RadioGroup, Radio, Dialog, Circle} from 'vant';
import flv from 'flv.js';
import Language from '/public/Language/Lang.json';  //语言包
// import '../public/ReConfig/index'

flv.LoggingControl.enableAll = false;
Vue.config.productionTip = false;
Vue.prototype.$ReConfig = window.Re;
Vue.prototype.SocketApi = SocketApi;
Vue.prototype.Calculation = Calculation;
Vue.prototype.Language = Language;
Vue.prototype.flv = flv;
Vue.mixin(MinXin);
// Vue.use(Vant);
Vue.use(Toast);
Vue.use(Slider);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(Circle);

const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

function SetRem() {
    const whdef = 100 / 1920;// 表示1920的设计图,使用100PX的默认值
    var bodyWidth = document.body.clientWidth < 1366 ? 1366 : document.body.clientWidth;// 当前窗口的宽度  vm.getWindowclientWidth(document.body.clientWidth)
    // let rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    let rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    let proportion = Number((bodyWidth / 1920).toFixed(2));
    app.SetStore({
        Prop: "whdef",
        Val: proportion
    });
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}

// router.beforeEach((to, from, next) => {
//     let {Page} = to.query;
//     let Open: Array<string> = ['LiveVideo', 'ComputerGame', 'FishingGame', 'ChessAndCardGames'];
//     if (Open.indexOf(Page)) {
//         next();
//     }
// });
window.addEventListener('load', SetRem);
window.addEventListener('resize', SetRem);
export default app;
