<template>
    <div id="app" class="absolute allw allh overflow-hidden">
        <router-view/>
        <SetVoice></SetVoice>
        <GameRules></GameRules>
        <SetPassWords></SetPassWords>
        <GameRecords></GameRecords>
        <GameRecharge></GameRecharge>
        <GameAppDown></GameAppDown>
        <GameOnline></GameOnline>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import SetVoice from './components/SetIng/SetVoice';
    import GameRules from './components/GameShows/GameRules';
    import SetPassWords from './components/SetIng/SetPassWords';
    import GameRecords from './components/GameShows/GameRecords';
    import GameRecharge from './components/GameShows/GameRecharge';
    import GameAppDown from './components/GameShows/GameAppDown';
    import GameOnline from './components/GameShows/GameOnline';

    export default {
        components: {SetVoice, GameRules, SetPassWords, GameRecords, GameAppDown, GameRecharge,GameOnline},
        computed: {
            ...mapState(['UserInfo', 'Heartbeat', 'DefaultConfig', 'LangeuageAgent'])
        },
        mounted() {
            this.$nextTick(() => {
                let TitleObj = {
                    'bl': '寳利皇宫娱乐城',
                    'dsgj': '鼎盛国际',
                    'yhyygj': '玉和永源国际',
                    'szwjt': '狮子王集团',
                    'hmdylc': '华美达娱乐城',
                    'wzzz': '王者至尊',
                    'hjgj': '皇家国际',
                    'yl': '兰桂坊娱乐',
                    'xsj': '新世界',
                    'wfgj': '萬豐.國際',
                };
                let imgarr = {
                    'bl': 'logo_bl',
                    'szwjt': 'logo_szwjt',
                    'hmdylc': 'logo_center_hmdylc',
                    'dsgj': 'logo_center_dsgj',
                    'yhyygj': 'logo_center_yhyygj',
                    'wzzz': 'logo_center_wzzz',
                    'hjgj': 'logo_center_hjgj',
                    'yl': 'logo_center_yl',
                    'xsj': 'logo_center_yl_xsj',
                    'wfgj': 'logo_center_wfgj',
                };
                // 设置 favicon
                const favicon = document.getElementById('dynamic-favicon');
                favicon.href = `./img/Login/${imgarr[this.DefaultConfig.LoginTemplate]}.png`; // 替换成你的 favicon 路径
                if (this.DefaultConfig.LoginTemplate) sessionStorage.LoginTemplate = this.DefaultConfig.LoginTemplate;
                document.title = TitleObj[this.DefaultConfig.LoginTemplate || sessionStorage.LoginTemplate];
            });
        },
        watch: {
            'Heartbeat': {
                handler(n, o) {
                    if (n) {
                        setInterval(() => { //每10秒发送一次心跳
                            if (sessionStorage.Heartbeat === 'true') this.SocketApi.SendSock([402, 444, 0, null]);
                        }, 10 * 1000);
                    }
                }, deep: true
            },
            '$route': {
                handler(n, o) {
                    //判断是否登录
                    if (sessionStorage.LaunchToken && !this.Heartbeat) {
                        this.Dialog(this.Lang['NYLKYX'], {Time: 9999999999999, Qr: false});//您已离开游戏，请关闭浏览器
                        return;
                    }
                    if (this.$route.name === null) return;
                    if (!this.Heartbeat && this.$route.name !== 'Login') {  //没用发起心跳则返回登陆 防止从快捷方式打开
                        this.$router.replace('/');
                    }
                },
                immediate: true
            }
        },
        methods: {}
    }
</script>
<style lang="less">
    @import '../public/css/index.less';

    html {
        font-family: MicrosoftYaHei;
        font-size: 100px;
        position: fixed;
        width: 100%;
        height: 100%;
        min-width: 1366px;
        min-height: 768px;
        line-height: 1;
    }

    .van-overlay {
        background-color: rgba(0, 0, 0, .3) !important;
    }

    .van-toast.van-toast--middle.van-toast--fail {
        width: unset !important;
    }
</style>
